import React, { useReducer, useRef } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { Pagination } from 'antd';
import searchicon from "../../../images/search.svg";
import location from "../../../../src/images/location.svg";
import cpma from "../../../images/cpma.svg";
import View from "../../../images/IC-view.svg";
import Loading from "../../../utils/loading/Loading";
import DarkView from "../../../images/DarkViewImg.svg";
import WhiteView from "../../../images/WhiteViewImg.svg";

import leftArrow from "../../../images/left-arrow.svg";
import Header from "../Citations-Header/container";
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { setAlert, setLoading } from "../../../store/actioncreator";
import { useDispatch } from "react-redux";
import { capitalize, debounce } from "../../../global";
import moment from "moment-timezone";
import Confirm_Modal from '../../../utils/modal'
import Refund_modal from "../../../utils/refund_modal";
import TextModal from "../../../utils/text_modal";
import { CSVLink } from "react-csv";
import Download from "../../../components/svgIcons/Download";

export default function AdminMailInCitationsComponent(props) {

  const tz = moment.tz?.guess()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [reason, setReason] = useState('')
  const [showTextModal, setShowTextModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");


  useEffect(() => {
    allCitationsinfo(1);
    // fetchName()
  }, []);


  const [role, setrole] = useState()

  const fetchName = async () => {
    // dispatch(setLoading(true))
    try {
      const { value } = await props.user();
      setrole(value?.user?.role)
    } catch (error) {
      dispatch(setAlert('error', error?.message))
    }
    dispatch(setLoading(false))
  };

  // console.log("role", role);
  const initialPageState = {
    total: 0,
    defaultCurrentPage: 1,
    currentPage: 1,
    pageSize: 10,
    showModal: false,
    refund_citation_id: null,
    refund_popup_title: '',
    operator_name : '',
    amount: 0,
    citation_type: null
  }

  const [pageState, setPageState] = useReducer((prev, next) => {
    if (next?.currentPage) {
      allCitationsinfo(next?.currentPage)
    }
    return { ...prev, ...next }
  }, initialPageState)

  console.log(pageState,"pageState---------->>>>");

  const [searchKey, setSearchKey] = useState('');
  const searchInputRef = useRef()

  const [data, setdata] = useState();

  // const [Operatorname, setOperatorname] = useState();
  // console.log(pageState?.operatorName,"-----555555------>>>>");

  async function allCitationsinfo(page) {
    dispatch(setLoading(true))
    try {
      const { value } = await props.allCitations({ page, size: 10, type: "mail_in" })
      if (value?.success) {
        setdata(value)
        console.log(value, "000000000000");
        setPageState({ total: value?.total })
      } else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message))
    }
    fetchName()
  }

  const handleModalClose = () => {
    setReason('');
    setrefund_role(undefined);
    setTaggrFee(false);
    setPageState({ showModal: false });
  };


  const [refund_role, setrefund_role] = useState();
  const [taggrFee, setTaggrFee] = useState(false);


  console.log(refund_role,"refund_role------->>>>");
  // console.log(taggrFee,"taggrFee------->>>>");

  const onDeleteModalSubmit = async () => {

    // console.log(taggrFee,"000000000000000000");

    dispatch(setLoading(true))
    let err
    if (!reason || reason?.trim()?.length <= 0) {
      err = true
      dispatch(setAlert('error', "Reason can't be empty!"))
    }

    if (refund_role === undefined) {
      err = true
      dispatch(setAlert('error', "Please select Issued by!"))
    }

    // Amount must be greater equal to 55 amount
    if (pageState?.amount < 55) {
      err = true
      dispatch(setAlert('error', "Invalid amount entered!"))
    }

    if (!err) {
      setPageState({ showModal: false })

      try {
        const { value } = await props.refund_citation(pageState?.refund_citation_id, reason,refund_role,taggrFee);
        if (value?.success) {
          allCitationsinfo(1);
          dispatch(setAlert("success", value?.message))
        } else {
          dispatch(setAlert("error", value?.message))
        }
      } catch (error) {
        dispatch(setAlert("error", error?.message))
      }
      setReason('')
    }
    dispatch(setLoading(false))
  };

  const on_void_citation_modal_submit = async () => {
    dispatch(setLoading(true))
    let err
    if (!reason || reason?.trim()?.length <= 0) {
      err = true
      dispatch(setAlert('error', "Reason can't be empty!"))
    }
    if (!err) {
      setPageState({ show_void_modal: false })

      try {
        const { value } = await props.void_citation(pageState?.void_citation_id, reason, taggrFee);
        if (value?.success) {
          allCitationsinfo(1);
          dispatch(setAlert("success", value?.message))
        } else {
          dispatch(setAlert("error", value?.message))
        }
      } catch (error) {
        dispatch(setAlert("erro", error?.message))
      }
      setReason('')
      setTaggrFee(false)
    }
    dispatch(setLoading(false))
  };


  const search = async query => {
    try {
      if (query?.length > 2) {
        const { value } = await props?.searchallCitations('citation_admin', query, "mail_in")
        if (value?.success) {
          setdata({ citation: value?.results })
        }
        else {
          dispatch(setAlert('error', value?.message))
        }
      }
    } catch (error) {
      dispatch(setAlert('error', error?.message))
    }
  }

  // const navigateToPaymentPage = () => {
  //   window.open('http://localhost:3002/citations/payment/callcenter', '_blank');
  // }

  const toggleTextModal = () => {
    setShowTextModal(!showTextModal);
  }

  const handleTextSend = async () =>{
    let err
    if (!phoneNumber) {
      err = true
      dispatch(setAlert('error', "Phone number cant be empty"))
    }
    dispatch(setLoading(true))
    if (!err) {
      const phoneNumberForLink = encodeURIComponent(phoneNumber);
      try {
        const { value } = await props.send_sms(phoneNumber, `${process.env.REACT_APP_CHATBOT_LINK}?phone_number=${phoneNumberForLink}`);
        if (value?.success) {
          dispatch(setAlert("success", value?.message))
        } else {
          dispatch(setAlert("error", value?.message))
        }
      } catch (error) {
        dispatch(setAlert("error", error?.message))
      }
    }
    setShowTextModal(!showTextModal);
    dispatch(setLoading(false))
}

  return (
    <>
      <section className="adminCitations">
        <Header />
        <div className="citationsSection paddzero" id="profile-wrapper">
          <div className="title-wrapper">
            <div className="d-flex align-items-center">
              {
                role !== "callcenter" ?
                  <img src={leftArrow} className="left-arrow " onClick={() => navigate(-1)} />
                  : ""
              }
              <h4>Mail In Tags Deployed</h4>
              </div>
            <div className="search-input">
              <button>
                <img src={searchicon} />
              </button>
              {/* <input type="search" placeholder="Search..." /> */}
              <input
                type="search"
                placeholder="Search..."
                ref={searchInputRef}
                onChange={async (e) => {
                  setSearchKey(e?.target?.value)
                  e?.target?.value?.length > 2 && debounce(async() => await search(e?.target?.value), 500)
                  e?.target?.value?.length === 0 && allCitationsinfo(1)
                }}
              />
            </div>
          </div>
          <div className="cirtation-wrapper">
            <div className="table-responsive">
              <table className="table offence-table StripTable">
                <thead>
                  <tr>
                    <th>Notice Number</th>
                    <th>Lot</th>
                    <th>Tag Serial Number</th>
                    {/* <th>Vehicle</th> */}
                    <th>Vehicle Plate</th>
                    <th>Status</th>
                    <th>Amount</th>
                    {/* <th>Transaction Id</th> */}
                    <th>Issued By</th>
                    <th>Issued</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    // data?.citation?.length > 0 ? (
                    data?.citation?.length > 0 ? (

                      data?.citation?.map((val) => {
                        console.log(val?.lotdata?.operator_fullname,"val_val");
                        const date = val?.tiretag_citation_data?.created_at
                        const mail_in_citation_date = val?.createdAt
                        const formattedDateTime = moment(date ? date :mail_in_citation_date)?.tz(tz).format('YYYY-MM-DD HH:mm:ss');
                        return (
                          <tr>
                            {/* <td>{val?.tiretag_citation_data?.citation_number}</td> */}
                            <td align="center">
                              {val?.citation_type === "mail_in"
                                ? val?.citation_mail_in_number
                                : val?.qr_code ?? val?.tiretag_citation_data?.citation_number}
                            </td>
                            <td align="center" className="lotText">
                              {val?.lotdata?.address}
                            </td>
                            <td align="center">{val?.tireTagSerialNumber}</td>
                            <td align="center">{val?.vehicle?.licensePlate}</td>
                            <td align="center">
                              <span
                                className={
                                  val?.payment_status === "paid"
                                    ? "green LabelStatus"
                                    : "red LabelStatus"
                                }
                              >
                                {capitalize(val?.payment_status)}
                              </span>
                            </td>

                            <td align="center">
                              $
                              {(
                                val?.break_down_amount?.calculative_amount +
                                val?.break_down_amount?.stripe_fees_offence +
                                (val?.break_down_amount?.dock_fees ? val?.break_down_amount?.dock_fees : 0)
                              )?.toFixed(2)}
                            </td>
                            {/* <td>{val?.txnId ?? "-"}</td> */}
                            <td align="center">{val?.taggr?.fullname ?? "-"}</td>
                            <td align="center">{formattedDateTime}</td>
                            <td>
                              {/* <a href="/citationsInformation"> */}
                              <div className="d-flex align-items-center">
                                <Link
                                  to={`/citationsInformation/${val?._id}`}
                                  className="eyeBtn"
                                >
                                  <img src={DarkView} className="DarkImg" />
                                  <img src={WhiteView} className="WhiteImg" />
                                </Link>

                                {/* <button  className={val?.payment_status === "voided" ? "DarkEditBtnDisbled" : "DarkEditBtn"}  */}
                                <button
                                  className="DarkEditBtnDisbled"
                                  onClick={() => {
                                    if (val?.payment_status === "voided") {
                                      dispatch(
                                        setAlert(
                                          "info",
                                          "Notice already voided."
                                        )
                                      );
                                    } else if (
                                      val?.payment_status !== "paid" &&
                                      val?.payment_status !== "refunded"
                                    ) {
                                      setPageState({
                                        void_citation_id: val?._id,
                                        citation_type: val?.citation_type,
                                        show_void_modal: true,
                                        void_popup_title: val?.citation_type === "tag_vehicle"
                                        ? val?.tiretag_citation_data?.citation_number
                                        : val?.citation_mail_in_number?.replace("MAIL_IN_", " "),
                                        amount: (
                                          val?.break_down_amount
                                            ?.calculative_amount +
                                          val?.break_down_amount
                                            ?.stripe_fees_offence
                                        )?.toFixed(2),
                                      });
                                    } else {
                                      dispatch(
                                        setAlert(
                                          "info",
                                          "You can't void a Notice after it's paid!"
                                        )
                                      );
                                    }
                                  }}
                                  disabled={(val?.payment_status !== "pending")}
                                >
                                  {val?.payment_status === "voided"
                                    ? "Voided"
                                    : "Void"}
                                </button>

                                {val?.payment_status === "paid" ||
                                val?.payment_status === "voided" ? (
                                  <button
                                    disabled={
                                      val?.payment_status === "paid" ||
                                      val?.payment_status === "voided"
                                    }
                                    className="DarkEditBtnDisbled"
                                  >
                                    Edit
                                  </button>
                                ) : (
                                  <Link
                                    to={`/Edit_citationsInformation/${val?._id}`}
                                    className="DarkBlackBtn"
                                  >
                                    Edit
                                  </Link>
                                )}
                                {val?.payment_status === "paid" ||
                                val?.payment_status === "voided" ? (
                                  <button
                                    disabled={
                                      val?.payment_status === "paid" ||
                                      val?.payment_status === "voided"
                                    }
                                    className="DarkEditBtnDisbled"
                                  >
                                    Pay
                                  </button>
                                ) : (
                                  <a
                                    className="DarkBlackBtn"
                                    href={`${
                                      process.env.REACT_APP_HOST ===
                                      "DEVELOPMENT"
                                        ? "http://localhost:3002"
                                        : "https://paytaggr.com"
                                    }?serialNumber=${
                                      val?.tireTagSerialNumber
                                    }&licensePlate=${
                                      val?.vehicle?.licensePlate
                                    }&payment_by=${
                                      role === "superAdmin"
                                        ? "admin"
                                        : role === "callcenter"
                                        ? "call_center"
                                        : ""
                                    }`}
                                  >
                                    Pay
                                  </a>
                                )}

                                {/* <button className="DarkGreenBtn">
                                    Pay
                                  </button> */}

                                {val?.payment_status === "pending" ? (
                                  !val.proof ? <button onClick={toggleTextModal}>
                                    Text
                                  </button>
                                  :
                                  <button
                                    className="proof-button"
                                    onClick={() => {
                                      window.location.href = `/citationsInformation/${val?._id}?is_proof=true`;
                                    }}
                                  >
                                    Proof
                                  </button>
                                ) : val?.payment_status === "pending" ||
                                val?.payment_status === "voided"||
                                val?.payment_status === "refunded" ? (
                                  <button
                                    disabled={
                                      val?.payment_status === "pending" ||
                                      val?.payment_status === "voided"||
                                      val?.payment_status === "refunded"
                                    }
                                    className="RefundBtnDisbled"
                                    onClick={() =>
                                      {setPageState({
                                        refund_citation_id: val?._id,
                                        operator_name : val?.lotdata?.operator_fullname,
                                        refund_popup_title : val?.tiretag_citation_data?.citation_number,
                                        showModal: true,
                                      })
                                    console.log(val?._id);
                                    }
                                    }
                                  >
                                    Refund
                                  </button>
                                ) : (
                                  <button
                                    className="DarkBlackBtn"
                                    onClick={() =>
                                      setPageState({
                                        refund_citation_id: val?._id,
                                        operator_name : val?.lotdata?.operator_fullname,
                                        refund_popup_title : val?.tiretag_citation_data?.citation_number,
                                        showModal: true,
                                         amount: (
                                          val?.break_down_amount
                                            ?.calculative_amount +
                                          val?.break_down_amount
                                            ?.stripe_fees_offence
                                        )?.toFixed(2)
                                      })
                                    }
                                  >
                                    Refund
                                  </button>
                                )}
                                {/* <button className="DarkBlackBtn" onClick={() => setPageState({
                                        refund_citation_id: val?._id,
                                        showModal: true
                                      })}>
                                    Refund
                                  </button> */}
                              </div>
                            </td>
                          </tr>
                        );

                      })

                    )
                      :
                      (
                        data?.citation === null || data?.citation?.length === 0 ?
                          searchKey &&
                          <div className="dataText">
                            <h5>No Result Found.</h5>
                          </div>
                          :
                          <div className="dataText">
                            <h5></h5>
                          </div>
                      )

                  }
                </tbody>
              </table>

              {data?.citation?.length > 0 && (
                // console.log("ooooo"),
                <div className='pagination-block'>
                  <Pagination
                    hideOnSinglePage
                    defaultCurrent={pageState?.defaultCurrentPage}
                    onChange={page => setPageState({ currentPage: page })}
                    current={pageState?.currentPage}
                    pageSize={pageState?.pageSize}
                    total={pageState?.total}
                    showSizeChanger={false}
                  />
                </div>
              )}


              {/* <div className='pagination-block'>
                <Pagination
                  hideOnSinglePage
                  defaultCurrent={pageState?.defaultCurrentPage}
                  onChange={page => setPageState({ currentPage: page })}
                  current={pageState?.currentPage}
                  pageSize={pageState?.pageSize}
                  total={pageState?.total}
                  showSizeChanger={false}
                />
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <Refund_modal
        show={pageState?.showModal}
        // onClose={() => setPageState({ showModal: false })}
        onClose={handleModalClose}
        setReason={(e) => setReason(e?.target?.value)}
        amount={pageState?.amount}
        reason={reason}
        setrefund_role={setrefund_role}
        refund_role={refund_role}
        setTaggrFee={setTaggrFee}
        taggrFee={taggrFee}
        onSubmit={onDeleteModalSubmit}
        title={pageState?.refund_popup_title}
        header_title="Refund"
        operator_name = {pageState?.operator_name}
      />
      <Refund_modal
        show={pageState?.show_void_modal}
        onClose={() => {
          setPageState({ show_void_modal: false })
          setReason('')
          setTaggrFee(false)
        }}
        setReason={(e) => setReason(e?.target?.value)}
        onSubmit={on_void_citation_modal_submit}
        amount={pageState?.amount}
        reason={reason}
        title={pageState?.void_popup_title}
        citation_type={pageState?.citation_type}
        header_title="Void"
        setTaggrFee={setTaggrFee}
        taggrFee={taggrFee}
      />

      <TextModal title="Send Dispute Form" show={showTextModal} onClose={toggleTextModal} onSubmit={handleTextSend} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
    </>
  );
}

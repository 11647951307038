import Loading from "../../utils/loading/Loading";
import React, { useEffect, useState, useReducer } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import dayjs, { Dayjs } from "dayjs";
import { delayLoading } from "../../global";
import leftArrow from "../../images/left-arrow.svg";
import Header from "../../components/common/Header/container";
import Form from "react-bootstrap/Form";
import checkbox from "../../images/Check.svg";
import Plus from "../../images/Plus.svg";
import Minus from "../../images/Minus.png";
import uploadFile from "../../images/upload-file.svg";
import close from "../../images/Close.svg";
import CloseWhite from "../../images/closewhite.svg";
import { setAlert } from "../../store/actioncreator";
import { useDispatch } from "react-redux";
import { returnShotLotName } from "../../global";
import moment from "moment-timezone";
import Select from "react-select";

export default function AddLotsComponent(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [operator, setOperator] = useState();
    const [open, setOpen] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [showList, setShowList] = useState([]);
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState("");

    const [taggingType, setTaggingType] = useState([]);
    const [permitAllowed, setPermitAllowed] = useState(true);
    const [selectedTimeZone, setSelectedTimeZone] = useState("");

    const [name, setName] = useState("");
    const [dbcode, setDbcode] = useState();
    const [facilityId, setfacilityId] = useState();
    const [pmc_locationId, setpmc_locationId] = useState();
    const [proParkId, setProParkId] = useState();
    const [dropboxDescription, setDropboxDescription] = useState("");
    const [lotRul, setLotRul] = useState([]);
    const [clrfld, setClrfld] = useState("");
    const [filename1, setFilename1] = useState("");
    const [filename2, setFilename2] = useState("");
    const [filename3, setFilename3] = useState("");
    const [address, setAddress] = useState("");
    const [isFetched, setisFetched] = useState(false);
    const [imageName1, setImageName1] = useState();
    const [imageName2, setImageName2] = useState();
    const [imageName3, setImageName3] = useState();
    const [notemptyModal, setnotemptyModal] = useState(false);
    const [providers, setProviders] = useState([]);
    const [isPaymentProviderShow, setIsPaymentProviderShow] = useState(false);
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [selectedProvidersImgs, setSelectedProvidersImgs] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
    const [deleteLotData, setDeleteLotData] = useState({});
    const [isEditLot, setIsEditLot] = useState({ edit: false, lotId: null });

    const [displayedOffences, setDisplayedOffences] = useState([]);

    const isDev = localStorage.getItem("isDev")
    const [fullAddress, setFullAddress] = useState(null);
    const [coordinates, setCoordinates] = useState({
        lat: null,
        lng: null,
    });

    const americanTimeZones = moment.tz.names().filter((zone) =>
      zone.startsWith("America/")
    );

    const handleChange = (selectedOption) => {
      setSelectedTimeZone(selectedOption?.value || null);
      console.log(`Selected Time Zone: ${selectedOption?.value}`);
    };


    const [split_address, set_split_address] = useState({
        street: "",
        city: "",
        state: "",
        zipcode: "",
    })


    const initialState = {
        offences: [],
        isOffenceShow: false,
        selectedOffences: []
    }
    const initialPayload = {
        selectedOffencesIds: [],
        holdingFee: 60,
        grace_period: 0,
        allowUnpaidCitations: 0,
        internalAccountingId: null,
        lot_space: 0 
    }

    const [pageState, setPageState] = useReducer((prev, next) => {
        return { ...prev, ...next }
    }, initialState)


    const [state, updateState] = useReducer((prev, next) => {
        return { ...prev, ...next }
    }, initialPayload)

    const [showImage, setShowImage] = useState(false)


    const [imagePreview, setImagePreview] = useState({
        parkingLot: "",
        dropbox: "",
        signage: "",
    });


    const handleChangeCheckbox = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            const k = taggingType.concat([value])
            setTaggingType(k);
        } else {
            setTaggingType(taggingType.filter((item) => item !== value));
        }

    };





    const handleChangeForpermit = (event) => {
        setPermitAllowed(event.target.value === '1');
    }

    ////////////////////////////////////my change 


    useEffect(() => {
        // checkOperator();
        // setLoading(true);
        getProviders();
        getOPeratorId();
        getActiveOffenceByOperator()
    }, []);



    useEffect(() => {
        const offences = state?.selectedOffences?.map(offence => offence?.offenceName);
        setDisplayedOffences(offences);
    }, [state?.selectedOffences]);



    async function getProviders() {
        try {
            const { value } = await props.getProviders();
            if (value?.success) {
                setProviders(value?.providers);
            } else {
                console.log(value?.message);
            }
        } catch (error) {
            console.log(error, "<<<error");
        }
    }

    async function getActiveOffenceByOperator() {
        try {
            const { value } = await props.getActiveOffenceByOperator();
            if (value?.success) {
                setPageState({ offences: value?.offences });
            } else {
                console.log(value?.message);
            }
        } catch (error) {
            console.log(error, "<<<error");
        }
    }

    const checkOperator = async () => {
        const { value } = await props.user();
        if (value.user.role === "operator") {
            Navigate("/yourlots");
            getOPeratorId();
        } else {
            Navigate("login");
        }
    };
    const handleClick = () => setOpen(true);
    const getOPeratorId = async () => {
        const { value } = await props.user();
        if (value?.success) {
            getLotList(value);
            setOperator(value);
        }
        setLoading(false);
    };

    const getLotList = async (value) => {
        const value1 = await props.lotprovider({ id: value.user._id });
        if (value1?.value?.lots) {
            setShowList(value1?.value?.lots);
        }
    };

    const deleteLot = async (id) => {
        const value1 = await props.deleteyourlot({ id: id });
        if (value1?.value?.lots) {
            setShowList(value1?.value?.lots);
            getLotList();
        }
    };

    const handleClose = () => {
        setLotRul([]);
        setAddress("");
        setSelectedProvidersImgs([]);
        setSelectedProviders([]);
        setName("");
        setDbcode("");
        setfacilityId("");
        setProParkId('')
        setDropboxDescription("");
        setFilename1("");
        setFilename2("");
        setFilename3("");
        setImageName1("");
        setImageName2("");
        setImageName3("");
        setIsEditLot({ edit: false, lotId: null });
        setImagePreview({ parkingLot: "", dropbox: "", signage: "" });
        setShow(false);
    };
    const handleShow = () => {
        setShow(true);
    };


    const [name_blank_error, set_name_blank_error] = useState()
    const [address_blank_error, set_address_blank_error] = useState()
    const [dbcode_blank_error, set_dbcode_blank_error] = useState()
    const [facilityId_blank_error, set_facilityId_blank_error] = useState()
    const [pmc_locationId_blank_error, set_pmc_locationId_blank_error] = useState()
    const [proParkId_blank_error, set_proParkId_blank_error] = useState()
    const [dropboxDescription_blank_error, set_dropboxDescription_blank_error] = useState()
    const [lotrule_blank_error, set_lotrule_blank_error] = useState()
    const [holdingfee_blank_error, set_holdingfee_blank_error] = useState()
    const [selectedProviders_blank_error, set_selectedProviders_blank_error] = useState()
    const [taggingType_blank_error, set_taggingType_blank_error] = useState()
    const [selectedOffences_blank_error, set_selectedOffences_blank_error] = useState(false)
    const [parkinglot_image_blank_error, set_parkinglot_image_blank_error] = useState()
    const [dropbox_image_blank_error, set_dropbox_image_blank_error] = useState()
    const [signage_image_blank_error, set_signage_image_blank_error] = useState()
    const [proof_images, setProof_images] = useState([]);

    const handleRemoveOptionalImage = (index) => {
        const updatedItems = proof_images.filter((_, i) => i !== index);
        setProof_images(updatedItems);
    };

    const handleUpdateOptionalImage = (index, updatedValues) => {
        const updatedItem = { ...proof_images[index], ...updatedValues };
        const updatedItems = [...proof_images.slice(0, index), updatedItem, ...proof_images.slice(index + 1)];
        setProof_images(updatedItems);
    };

    const [nErr, setNErr] = useState(false)

    const onAdd = async () => {
        seterrmessage(false)
        seterrmessage2(false)
        seterrmessagefacilityId(false)
        seterrmessageproParkId(false)
        console.log('ON ADD FUNCTION ------------------------------------------')
        setLoading(true);


        let err = false

        if (name?.trim() === "") {
            err = true
            set_name_blank_error("Please enter name")
        }

        if (address?.trim() === '') {
            err = true
            set_address_blank_error("Please enter address")
        }

        if (!dbcode || dbcode?.trim() === 0) {
            err = true
            set_dbcode_blank_error("Please enter dropbox code")
        }

        if (!dropboxDescription || dropboxDescription?.trim() === "") {
            err = true
            set_dropboxDescription_blank_error("Please enter dropbox description")
        }

        if (lotRul?.length === 0) {
            err = true
            set_lotrule_blank_error("Please enter lot rule")
        }

        if (state?.holdingFee?.length === 0) {
            err = true
            set_holdingfee_blank_error("Please enter holding fee")
        }


        if (selectedProviders?.length === 0) {
            err = true
            set_selectedProviders_blank_error("Please select payment provider")
        }

        if (taggingType?.length === 0) {
            err = true
            set_taggingType_blank_error("Please select enforcement Options")
        }


        if (state?.selectedOffencesIds?.length <= 0) {
            err = true
            set_selectedOffences_blank_error(true)
        }

        if (filename1?.length === 0) {
            err = true
            set_parkinglot_image_blank_error("Please select parking lot image")
        }

        if (filename2?.length === 0) {
            err = true
            set_dropbox_image_blank_error("Please select dropbox image")
        }

        if (filename3?.length === 0) {
            err = true
            set_signage_image_blank_error("Please select signage image")
        }

        setLoading(false);


        if (!err) {
            setLoading(true);
            try {
                const { value } = await props.addlots(
                    {
                        operator: operator?.user?._id,
                        lotRules: JSON.stringify(lotRul),
                        address: address,
                        split_address,
                        paymentProviders: JSON.stringify(selectedProviders),
                        lotname: name,
                        time_zone_name: selectedTimeZone,
                        dropbox: JSON.stringify({
                            dropboxCode: dbcode,
                            dropboxDescription,
                        }),
                        parkingLot: filename1,
                        dropBox: filename2,
                        signage: filename3,
                        offences: JSON.stringify(state?.selectedOffencesIds),
                        permitAllowed: permitAllowed,
                        taggingType: [...taggingType],
                        holdingFee: state?.holdingFee,
                        grace_period: state?.grace_period || 0,
                        allowUnpaidCitations: state?.allowUnpaidCitations || 0,
                        coordinates,
                        proof_images: proof_images,
                        facilityId: facilityId ? parseInt(facilityId) : '',
                        internalAccountingId: state?.internalAccountingId,
                        lot_space: state?.lot_space,
                        pmc_locationId: pmc_locationId,
                        pro_park_lotId: proParkId
                    },
                    isEditLot?.lotId,
                    isEditLot?.edit
                );
                if (value?.success) {
                    // setLoading(true);
                    setShow(false);
                    setisFetched(true);
                    getOPeratorId();
                    setLotRul([]);
                    setAddress("");
                    setSelectedProvidersImgs([]);
                    setSelectedProviders([]);
                    setName("");
                    setDbcode("");
                    setfacilityId();
                    setpmc_locationId('')
                    setProParkId('')
                    setDropboxDescription("");
                    setFilename1("");
                    setFilename2("");
                    setFilename3("");
                    setImageName1("");
                    setImageName2("");
                    setImageName3("");
                    setIsEditLot({ edit: false, lotId: null });
                    delayLoading(setLoading);
                    setTaggingType([])
                    updateState(initialPayload)
                    dispatch(setAlert("success", value?.message))
                    navigate("/lots")
                } else {
                    setLoading(false);
                    setnotemptyModal(true);
                    dispatch(setAlert("error", value?.message))
                }
            } catch (error) {
                setLoading(false);
                dispatch(setAlert("error", error?.message))
            }
        }
    };

    const makeAddLotClose = () => {
        setnotemptyModal(false);
        setShow(false);
    };


    const [errmessage, seterrmessage] = useState()
    const [errmessage1, seterrmessage1] = useState()
    const [errmessage2, seterrmessage2] = useState()
    const [errmessagefacilityId, seterrmessagefacilityId] = useState()
    const [errmessagepmc_locationId, seterrmessagepmc_locationId] = useState()
    const [errmessageproParkId, seterrmessageproParkId] = useState()


    const handleKeyDown = (e) => {
        if (e?.key === "Enter" || e?.button === 0) {
            e.preventDefault();
            if (clrfld?.trim()?.length === 0 || clrfld?.trim()?.length < 5) {
                seterrmessage("Please enter at least 5 characters for Lot Rules");
                set_lotrule_blank_error(false)
            } else {
                let arr = [...lotRul];
                arr?.push(clrfld);
                setLotRul(arr);
                setClrfld("");
                seterrmessage("");
            }
        }
    };


    const handleButtonClick = () => {
        seterrmessage("");
    };



    ////////////////////////////////////////////
    const getFilename1 = (e) => {
        setFilename1(e[0]);
        setImageName1(e[0]?.name);
    };
    const getFilename2 = (e) => {
        setFilename2(e[0]);
        setImageName2(e[0]?.name);
    };
    const getFilename3 = (e) => {
        setFilename3(e[0]);
        setImageName3(e[0]?.name);
    };
    useEffect(() => {
        getOPeratorId();
    }, [isFetched]);

    const handleProvider = (provider) => {
        const isAlreadySelected = selectedProviders?.indexOf(provider?._id);
        const isAlreadySelectedLogo = selectedProvidersImgs?.indexOf(
            provider?.logoUrl
        );
        if (isAlreadySelected === -1 && isAlreadySelectedLogo === -1) {
            setSelectedProviders([...selectedProviders, provider?._id]);
            setSelectedProvidersImgs([...selectedProvidersImgs, provider?.logoUrl]);
        } else {
            let oldProviders = selectedProviders;
            let oldProvidersImgs = selectedProvidersImgs;
            oldProviders?.splice(isAlreadySelected, 1);
            oldProvidersImgs?.splice(isAlreadySelectedLogo, 1);
            setSelectedProviders([...oldProviders]);
            setSelectedProvidersImgs([...selectedProvidersImgs]);
        }
    };


    const handleOffences = (offence) => {
        const isAlreadySelected = pageState?.selectedOffences?.indexOf(offence);
        const isAlreadySelectedId = state?.selectedOffencesIds?.indexOf(offence?._id);
        if (isAlreadySelected === -1) {
            updateState({ selectedOffencesIds: [...state?.selectedOffencesIds, offence?._id] });
            setPageState({ selectedOffences: [...pageState?.selectedOffences, offence] });

            // set_selectedOffences_blank_error(false);

        } else {
            let oldOffences = pageState?.selectedOffences;
            let oldOffencesIds = state?.selectedOffencesIds;
            oldOffences?.splice(isAlreadySelected, 1);
            oldOffencesIds?.splice(isAlreadySelectedId, 1);
            updateState({ selectedOffencesIds: [...oldOffencesIds] })
            setPageState({ selectedOffences: [...oldOffences] })
        }
    };

    const removeLot = (data) => {
        const index = lotRul?.indexOf(data);
        if (index !== -1) {
            let newArr = lotRul;
            newArr?.splice(index, 1);
            setLotRul([...newArr]);
        }
    };

    const onDelete = (id, title) => {
        setDeleteLotData({ id, title });
        setIsDeleteModalShow(true);
    };

    const onDeleteModalSubmit = async () => {
        setLoading(true);
        setIsDeleteModalShow(false);
        try {
            const { value } = await props.deleteyourlot(deleteLotData?.id);
            if (value?.success) {
                getOPeratorId();
                delayLoading(setLoading);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    // HANDLE EDIT BUTTON CLICK
    const handleEditButton = async (id) => {
        setLoading(true);
        try {
            const { value } = await props.getLotsById(id);
            if (value?.success) {
                const { lot } = value;
                setName(lot?.lotname);
                setAddress(lot?.address);
                setDbcode(lot?.dropbox?.dropboxCode);
                setfacilityId(lot?.facilityId);
                setpmc_locationId(lot?.pmc_locationId);
                setProParkId(lot?.pro_park_lotId)
                setDropboxDescription(lot?.dropbox?.dropboxDescription);
                setDropboxDescription(lot?.dropbox?.dropboxDescription);
                setSelectedProvidersImgs(
                    lot?.paymentProviders?.map((pp) => pp?.logoUrl)
                );
                setSelectedProviders(lot?.paymentProviders?.map((pp) => pp?._id));
                setLotRul(lot?.lotRules);
                setImageName1("Parking Lot");
                setFilename1(lot?.parkingLotImage[0]);
                setImageName2("Drop box");
                setFilename2(lot?.dropBoxImage[0]);
                setImageName3("Signage");
                setFilename3(lot?.signageImage[0]);
                delayLoading(setLoading, 1000);

            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const handleImagePreview = (e, name) => {
        const file = e?.target?.files[0];
        console.log(file);
        if (file) {
            name === "parkingLot" &&
                setImagePreview({
                    ...imagePreview,
                    parkingLot: file?.length > 0 ?
                        URL.createObjectURL(file[0]) : ""
                });
            name === "dropbox" &&
                setImagePreview({
                    ...imagePreview,
                    dropbox: file?.length > 0 ?
                        URL.createObjectURL(file[0]) : ""
                });
            name === "signage" &&
                setImagePreview({
                    ...imagePreview,
                    signage: file?.length > 0 ?
                        URL.createObjectURL(file[0]) : ""
                });
        }
    };

    const clearerr = () => {
        seterrmessage(false)
        seterrmessage1(false)
        set_dbcode_blank_error(false)
        set_dropboxDescription_blank_error(false)
        seterrmessage2(false)
        seterrmessagefacilityId(false)
        seterrmessagepmc_locationId(false)
        set_facilityId_blank_error(false)
        set_pmc_locationId_blank_error(false)
        seterrmessageproParkId(false)
        set_name_blank_error(false)
        set_address_blank_error(false)
        set_lotrule_blank_error(false)
        set_holdingfee_blank_error(false)
        set_selectedProviders_blank_error(false)
        set_taggingType_blank_error(false)
        set_parkinglot_image_blank_error(false)
        set_dropbox_image_blank_error(false)
        set_signage_image_blank_error(false)
        set_selectedOffences_blank_error(false)
    }

    return (
        <>
            {isLoading && <Loading />}
            <section className="mainWrapper">
                <Header />
                <div className="main-wrapper paddzero" id="profile-wrapper">
                    <div className="HeadingSection addlote-title">
                        <img src={leftArrow} className="left-arrow" onClick={() => navigate(-1)} />
                        <h4>Add Lot</h4>
                    </div>
                    <div className="add-lot-block DetailsWrapper">

                        <Form className="row add-lots" onSubmit={(e) => {
                            e.preventDefault();
                            onAdd();
                        }}
                        >

                            <Form.Group
                                className="mb-3 col-lg-6 col-sm-12"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Lot Name<span className="mandatory Error">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Lot Name"
                                    value={name}
                                    onClick={clearerr}
                                    maxLength={50}
                                    onChange={(e) => {
                                        setName(e.target.value)
                                        set_name_blank_error(false)
                                        set_address_blank_error(false)
                                    }}
                                // onChange={(e) => {
                                //     if (/^\d+$/.test(e.target.value)) {
                                //         // alert('Numbers are not allowed!');
                                //         seterrmessage1('Numbers are not allowed!')
                                //     } else {
                                //         const regex = /^[A-Za-z@#$ ]+$/;
                                //         if (regex.test(e.target.value) || e.target.value === '') {
                                //             setName(e.target.value);
                                //             seterrmessage1(false)
                                //         }
                                //     }
                                // }}
                                />
                                {errmessage1 && <p className="Error">{errmessage1}</p>}
                                {name_blank_error && <p className="Error">{name_blank_error}</p>}
                                {/* <div className="Error">test error</div>  */}
                            </Form.Group>

                            <Form.Group
                                className="mb-3 col-lg-6 col-sm-12 "
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Address<span className="mandatory Error">*</span></Form.Label>
                                {/* <Form.Control
                                    placeholder="Enter Address"
                                    value={address}
                                    onClick={clearerr}
                                    maxLength={50}
                                    onChange={(e) => setAddress(e.target.value)}
                                /> */}
                                <PlacesAutocomplete
                                    className="dscsdc"
                                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY_FOR_ADDRESS}
                                    apiOptions={{ language: 'en', region: 'us' }}
                                    autocompletionRequest={{
                                        componentRestrictions: {
                                            country: ["us", "in", "pk"], //to set the specific country
                                        },
                                    }}
                                    selectProps={{
                                        isClearable: true,

                                        onChange: (e) => {
                                            setAddress(e?.label)
                                            set_address_blank_error(false)
                                            set_name_blank_error(false)
                                            geocodeByAddress(e?.label)
                                                .then(async (results) => {
                                                    const { lat, lng } = await getLatLng(results[0]);
                                                    // Extract address components
                                                    const addressComponents = results[0].address_components;
                                                    let street, city, state, zipcode;
                                                    for (let i = 0; i < addressComponents.length; i++) {
                                                        const component = addressComponents[i];
                                                        const componentType = component.types[0];

                                                        if (componentType === 'street_number') {
                                                            street = component.long_name;
                                                        } else if (componentType === 'route') {
                                                            street += ' ' + component.long_name;
                                                        } else if (componentType === 'locality') {
                                                            city = component.long_name;
                                                        } else if (componentType === 'administrative_area_level_1') {
                                                            state = component.short_name;
                                                        } else if (componentType === 'postal_code') {
                                                            zipcode = component.long_name;
                                                        }
                                                    }

                                                    console.log('Street address:', street);
                                                    console.log('City:', city);
                                                    console.log('State:', state);
                                                    console.log('Zip code:', zipcode);
                                                    set_split_address({
                                                        street,
                                                        city: city?.toLocaleLowerCase(),
                                                        state,
                                                        zipcode
                                                    })
                                                    setCoordinates({ lat, lng });
                                                }
                                                )
                                                .catch((error) => console.error('Error', error));

                                        }
                                    }}
                                    onLoadFailed={(error) => {
                                        dispatch(setAlert("error", error?.message || 'An unknown error occurred!'))
                                    }}
                                />
                                {address_blank_error && <p className="Error">{address_blank_error}</p>}
                            </Form.Group>
                            <Form.Group
                                className="mb-1 col-lg-6 col-sm-12"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Drop Box Details<span className="mandatory Error">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Drop Box Code"
                                    value={dbcode}
                                    maxLength={8}
                                    autoComplete="off"
                                    onClick={clearerr}
                                    onChange={(e) => {
                                        const regex = /^[0-9\b]+$/;
                                        if (e.target.value === '' || regex.test(e.target.value)) {
                                            setDbcode(e.target.value)
                                            seterrmessage2(false)
                                        } else {
                                            if (e.target.value?.length === 1) {
                                                setDbcode("")
                                            }
                                            // alert("Text are not allowed")
                                            seterrmessage2("Text are not allowed")
                                            set_dbcode_blank_error(false)
                                        }
                                    }}

                                />
                                {errmessage2 && <p className="Error">{errmessage2}</p>}
                                {dbcode_blank_error && <p className="Error">{dbcode_blank_error}</p>}
                                <textarea
                                    placeholder="Describe the location of your drop box"
                                    value={dropboxDescription}
                                    onClick={clearerr}
                                    className="textarea-style mt-3"
                                    onChange={(e) => {
                                        setDropboxDescription(e.target.value)
                                        set_dropboxDescription_blank_error(false)
                                    }}
                                />
                                {dropboxDescription_blank_error && <p className="Error">{dropboxDescription_blank_error}</p>}

                                <div className=" d-flex align-items-center justify-content-between vendor-block ">
                                    <label>Select Payment Vendors<span className="mandatory Error">*</span></label>
                                    <div className="payment-vendor">
                                        <button
                                            id="toggleProvider"
                                            type="button"
                                            className="paymentVendorBtn"
                                            onClick={() => {
                                                setIsPaymentProviderShow(true);
                                                document
                                                    .getElementById("toggleProvider")
                                                    ?.classList?.toggle("addVendore");
                                            }}
                                        >
                                            <div className="add-vendor">
                                                {selectedProvidersImgs?.length > 0 &&
                                                    selectedProvidersImgs?.map((e, index) => {
                                                        if (index < 3) {
                                                            return (
                                                                <img
                                                                    src={e}
                                                                    alt="payment provider"
                                                                    key={index}
                                                                />
                                                            );
                                                        }
                                                    })}
                                                {selectedProvidersImgs?.length > 3 && (
                                                    <span>
                                                        +{selectedProvidersImgs?.length - 3}
                                                    </span>
                                                )}
                                            </div>
                                            <span className="dropdown-arrow"></span>
                                        </button>
                                        {isPaymentProviderShow && (
                                            <div className="paymentWrapper" onClick={() => setIsPaymentProviderShow(false)}>
                                                <div>
                                                    <div className="paymentVendorblock" onClick={e => e.stopPropagation()}>
                                                        <img
                                                            src={close}
                                                            onClick={() => {
                                                                setIsPaymentProviderShow(false);
                                                            }}
                                                            className="close-btn"
                                                        />
                                                        <ul className="paymentVendorlist">
                                                            {
                                                                // providers?.length > 0 &&
                                                                //     providers?.map((provider, index) => {
                                                                //         console.log(
                                                                //             provider?._id,
                                                                //             provider?.provider,
                                                                //             "<<<<selected providers"
                                                                //         );
                                                                //         return (
                                                                //             <li
                                                                //                 key={index}
                                                                //                 onClick={() => handleProvider(provider)}
                                                                //             >
                                                                //                 <div>
                                                                //                     <img
                                                                //                         src={provider?.logoUrl}
                                                                //                         alt={provider?.logoUrl}
                                                                //                     />
                                                                //                     {provider?.provider}
                                                                //                 </div>
                                                                //                 <div className="vendor-checkbox">
                                                                //                     <input
                                                                //                         type="checkbox"
                                                                //                         id={`weekday-mon-${index}`}
                                                                //                         class="weekday"
                                                                //                         checked={
                                                                //                             selectedProviders?.indexOf(
                                                                //                                 provider?._id
                                                                //                             ) !== -1
                                                                //                         }
                                                                //                     />
                                                                //                     <label
                                                                //                         for={`weekday-mon-${index}`}
                                                                //                     ></label>
                                                                //                 </div>
                                                                //             </li>
                                                                //         );
                                                                //     })
                                                                providers
                                                                ?.filter((provider) => (operator?.user?.allowed_pp?.length > 0 || operator?.user?.operatorInfo?.allowed_pp?.length > 0) ?  (operator?.user?.allowed_pp?.includes(provider?.provider) || operator?.user?.operatorInfo?.allowed_pp?.includes(provider?.provider))
                                                                : (provider?.provider === "Spot Hero" ||
                                                                provider?.provider === "Pro Park" ||
                                                                 provider?.provider === "Dock" ||
                                                                 provider?.provider === "PMC" ||
                                                                 provider?.provider === "Dock 2.0" ||
                                                                 provider?.provider === "Premium Parking" ||
                                                                  operator?.user?.isT2Enabled && (provider?.provider === "T2 Mobile Pay") ||
                                                                   operator?.user?.isParkmobileEnabled && ( provider?.provider === 'Parkmobile'))) // filter out non-payment providers
                                                                    .map((provider, index) => {
                                                                        console.log(provider?._id, provider?.provider, "<<<<selected providers");
                                                                        return (
                                                                            <li key={index} onClick={() => { handleProvider(provider); clearerr(); }}>
                                                                                <div>
                                                                                    <img src={provider?.logoUrl} alt={provider?.logoUrl} />
                                                                                    {provider?.provider}
                                                                                </div>
                                                                                <div className="vendor-checkbox">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id={`weekday-mon-${index}`}
                                                                                        class="weekday"
                                                                                        checked={selectedProviders?.indexOf(provider?._id) !== -1}
                                                                                    />
                                                                                    <label for={`weekday-mon-${index}`}></label>
                                                                                </div>
                                                                            </li>
                                                                        );
                                                                    })

                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {selectedProviders_blank_error && <p className="Error">{selectedProviders_blank_error}</p>}


                                <div className="d-flex justify-content-between vendor-block">
                                    <label>Permits Allowed<span className="mandatory Error">*</span></label>
                                    <select
                                        className="form-select"
                                        aria-label="Default example"
                                        value={permitAllowed ? '1' : '2'}
                                        onChange={handleChangeForpermit}
                                    >
                                        <option value="1">Yes</option>
                                        <option value="2">No</option>
                                    </select>

                                </div>

                                <Form.Group
                                    className="col-12"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <div className="upload-img">
                                        <label className=" form-label">Upload Images<span className="mandatory Error">*</span></label>
                                        <ul className="ParkingList marginBotmw">
                                            <li>
                                                {isEditLot?.edit && filename1 ? (
                                                    <div>
                                                        <img
                                                            src={CloseWhite}
                                                            alt="X"
                                                            onClick={() => {
                                                                setImageName1("");
                                                                setFilename1("");
                                                            }}
                                                            className="CloseBtn"
                                                        />
                                                        <img
                                                            src={
                                                                imagePreview?.parkingLot
                                                                    ? imagePreview?.parkingLot
                                                                    : filename1
                                                            }
                                                            alt="Parking lot"
                                                            className="uploaded-img"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="file-upload ">
                                                            <img src={uploadFile} alt="" />
                                                            {imageName1 ? (
                                                                <p>{imageName1}</p>
                                                            ) : (
                                                                <p>Parking Lot</p>
                                                            )}

                                                            <input
                                                                type="file"
                                                                name="file"
                                                                accept="image/*"
                                                                onClick={clearerr}
                                                                onChange={(e) => {
                                                                    handleImagePreview(e, "parkingLot");
                                                                    getFilename1(e?.target?.files);
                                                                }}
                                                            />

                                                        </div>
                                                        {parkinglot_image_blank_error && <p className="Error">{parkinglot_image_blank_error}</p>}
                                                    </div>
                                                )}
                                            </li>
                                            <li>
                                                {isEditLot?.edit && filename2 ? (
                                                    <div>
                                                        <img
                                                            src={CloseWhite}
                                                            alt="X"
                                                            onClick={() => {
                                                                setImageName2("");
                                                                setFilename2("");
                                                            }}
                                                            className="CloseBtn"
                                                        />
                                                        <img
                                                            src={
                                                                imagePreview?.dropbox
                                                                    ? imagePreview?.dropbox
                                                                    : filename2
                                                            }
                                                            alt="Drop box"
                                                            className="uploaded-img"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="file-upload ">
                                                            <img src={uploadFile} />
                                                            {imageName2 ? (
                                                                <p>{imageName2}</p>
                                                            ) : (
                                                                <p>Drop Box</p>
                                                            )}
                                                            <input
                                                                type="file"
                                                                name="file"
                                                                accept="image/*"
                                                                onClick={clearerr}
                                                                onChange={(e) => {
                                                                    handleImagePreview(e, "dropbox");
                                                                    getFilename2(e.target?.files);
                                                                }}
                                                            />
                                                        </div>
                                                        {dropbox_image_blank_error && <p className="Error">{dropbox_image_blank_error}</p>}
                                                    </div>
                                                )}
                                            </li>
                                            <li>
                                                {isEditLot?.edit && filename3 ? (
                                                    <div>
                                                        <img
                                                            src={CloseWhite}
                                                            alt="X"
                                                            onClick={() => {
                                                                setImageName3("");
                                                                setFilename3("");
                                                            }}
                                                            className="CloseBtn"
                                                        />
                                                        <img
                                                            src={
                                                                imagePreview?.signage
                                                                    ? imagePreview?.signage
                                                                    : filename3
                                                            }
                                                            alt="Signage"
                                                            className="uploaded-img"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="file-upload">
                                                            <img src={uploadFile} />
                                                            {imageName3 ? (
                                                                <p>{imageName3}</p>
                                                            ) : (
                                                                <p>Signage</p>
                                                            )}
                                                            <input
                                                                type="file"
                                                                name="file"
                                                                accept="image/*"
                                                                onClick={clearerr}
                                                                onChange={(e) => {
                                                                    handleImagePreview(e, "signage");
                                                                    getFilename3(e.target?.files);
                                                                }}
                                                            />

                                                        </div>
                                                        {signage_image_blank_error && <p className="Error">{signage_image_blank_error}</p>}
                                                    </div>
                                                )}
                                            </li>
                                        </ul>
                                    </div>
                                </Form.Group>

                                <div className="d-flex flex-column justify-content-start align-items-start vendor-block mb-4">
                                    <label className="mb-4">Proof Images</label>
                                    {proof_images?.length > 0 ? (
                                        <Form.Group
                                            className="col-12"
                                            controlId="exampleForm.ControlTextarea1"
                                        >
                                        <div>
                                            <ul className="ParkingList mb-4">
                                                {proof_images.map((item, index) => (
                                                    <li className="posirel">
                                                        {item.ImagePreview ? (
                                                            <div>
                                                                <img
                                                                    src={CloseWhite}
                                                                    alt="X"
                                                                    onClick={() => handleRemoveOptionalImage(index)}
                                                                    className="CloseBtn"
                                                                />
                                                                <div className="ImgBox">
                                                                    <img
                                                                        src={item.ImagePreview}
                                                                        alt={`Proof ${index + 1}`}
                                                                        className="uploaded-img"
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <img
                                                                    src={CloseWhite}
                                                                    alt="X"
                                                                    onClick={() => handleRemoveOptionalImage(index)}
                                                                    className="CloseBtn"
                                                                />
                                                                <div className="file-upload">
                                                                    <img src={uploadFile} />
                                                                    <p>Proof {index + 1}</p>
                                                                    <input
                                                                        type="file"
                                                                        name="file"
                                                                        accept="image/*"
                                                                        onChange={(e) => {
                                                                            handleUpdateOptionalImage(index, {
                                                                                file: e?.target?.files[0],
                                                                                ImageName: e?.target?.files[0].name,
                                                                                ImagePreview: [URL.createObjectURL(e?.target?.files[0])],
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        </Form.Group>
                                    ) : null}
                                    <button
                                        className="btn btn-secondary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if(proof_images.length < 4)
                                            {
                                                setProof_images((prevImages) => [...prevImages, {}]);
                                            }else{
                                                dispatch(setAlert("error", "Maximum 7 images allowed"))
                                            }
                                        }}
                                    >
                                        Add Proof Image
                                    </button>
                                </div>
                            </Form.Group>
                            <Form.Group
                                className="mb-2 col-lg-6 col-sm-12"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Lot Rules<span className="mandatory Error">*</span></Form.Label>
                                <div class="input-group control-group after-add-more">
                                    <div className="add-lots-feild">
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Lot Rules"
                                            value={clrfld}
                                            onClick={clearerr}
                                            onChange={(e) => {
                                                setClrfld(e.target.value)
                                                seterrmessage(false)
                                                set_name_blank_error(false)
                                                set_address_blank_error(false)
                                                set_lotrule_blank_error(false)
                                            }}
                                            onKeyDown={(e) => handleKeyDown(e)}
                                        />
                                    </div>
                                    <div class="input-group-btn mb-3">
                                        <button
                                            className=" add-more"
                                            type="button"
                                            onClick={(e) => { handleKeyDown(e); console.log("*****"); }}
                                        >
                                            <img src={Plus} alt="Plus icon" />
                                        </button>
                                    </div>
                                </div>
                                {errmessage && <p className="Error">{errmessage}</p>}
                                {lotrule_blank_error && <p className="Error">{lotrule_blank_error}</p>}

                                <div className="add-field mt-3">
                                    {lotRul.length > 0 &&
                                        lotRul.map((data, index) => {
                                            return (
                                                <p key={index}>
                                                    {data}
                                                    <span onClick={() => removeLot(data)}>
                                                        <img src={Minus} alt="minus icon" />
                                                    </span>
                                                </p>
                                            );
                                        })}
                                </div>
                                {/* <Form.Label>
                                    Lot Rules<span className="mandatory Error">*</span>
                                </Form.Label>
                                <div className="input-group control-group after-add-more mb-3">
                                    <div className="add-lots-feild">
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Lot Rules"
                                            value={clrfld}
                                            onClick={clearerr}
                                            onChange={(e) => {
                                                setClrfld(e.target.value);
                                                seterrmessage(false);
                                            }}
                                            onKeyDown={(e) => handleKeyDown(e)} // Add keydown event listener
                                        />
                                    </div>
                                    <div className="input-group-btn mb-3">
                                        <button
                                            className="btn  add-more"
                                            type="button"
                                            onClick={(e) => handleKeyDown(e)}
                                        >
                                            <img src={Plus} alt="Plus icon" />
                                        </button>
                                    </div>
                                </div>
                                {errmessage && <p className="Error">{errmessage}</p>}
                                <div className="add-field">
                                    {lotRul.length > 0 &&
                                        lotRul.map((data, index) => {
                                            return (
                                                <p key={index}>
                                                    {data}
                                                    <span onClick={() => removeLot(data)}>
                                                        <img src={Minus} alt="minus icon" />
                                                    </span>
                                                </p>
                                            );
                                        })}
                                </div> */}
                                <div className="d-flex align-items-center justify-content-between holding-feels mb-3">
                                    <Form.Label>Holding Fees<span className="mandatory Error">*</span></Form.Label>
                                    <div className="InputDollorWrap w-25">
                                        <span className="DollorSign">$</span>
                                        <input className="form-control InputStyle" placeholder="0" type="text"
                                            value={state?.holdingFee}
                                            onClick={clearerr}
                                            // onChange={(e) => updateState({ holdingFee: e?.target?.value })} 
                                            onChange={(e) => {
                                                const inputValue = e?.target?.value;
                                                const regex = /^[0-9]*$/;
                                                if (regex.test(inputValue)) {
                                                    updateState({ holdingFee: inputValue });
                                                }
                                            }}
                                        />
                                    </div>
                                    {holdingfee_blank_error && <p className="Error">{holdingfee_blank_error}</p>}
                                    <Form.Label style={{paddingLeft: 15}}>Grace Time</Form.Label>
                                    <div className="InputDollorWrap w-25">
                                        <input className="form-control InputStyle" placeholder="0-60" type="text"
                                            name="grace_period"
                                            value={state?.grace_period}
                                            onClick={clearerr}
                                            // onChange={(e) =>  onChange(e)}
                                            onChange={(e) => {
                                                const inputValue = e?.target?.value;
                                                const regex = /^\$?[0-9]*$/;
                                                if (regex.test(inputValue) && inputValue <=60 ) {
                                                    updateState({ grace_period: inputValue });
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between holding-feels mb-3">
                                    <Form.Label>Unpaid Notices</Form.Label>
                                    <div className="InputDollorWrap w-25 ms-3">
                                        <input className="form-control InputStyle" placeholder="0" type="text"
                                            name="allowUnpaidCitations"
                                            value={state?.allowUnpaidCitations}
                                            onClick={clearerr}
                                            onChange={(e) => {
                                                const inputValue = e?.target?.value;
                                                const regex = /^\$?[0-9]*$/;
                                                if (regex.test(inputValue)) {
                                                    updateState({ allowUnpaidCitations: inputValue });
                                                }
                                            }}
                                        />
                                    </div>
                                    <Form.Label>Internal <br /> Accounting ID</Form.Label>
                                    <div className="InputDollorWrap w-25 ms-3">
                                        <input className="form-control InputStyle" type="text"
                                            name="internalAccountingId"
                                            value={state?.internalAccountingId}
                                            onClick={clearerr}
                                            onChange={(e) => updateState({ internalAccountingId: e?.target?.value })}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center holding-feels mb-3">
                                    <Form.Label className="me-5 pe-3">Lot Space</Form.Label>
                                    <div className="InputDollorWrap w-25 ms-3">
                                        <input className="form-control InputStyle" placeholder="0" type="text"
                                            name="lot_space"
                                            value={state?.lot_space}
                                            onClick={clearerr}
                                            onChange={(e) => {
                                                const inputValue = e?.target?.value;
                                                const regex = /^\$?[0-9]*$/;
                                                if (regex.test(inputValue)) {
                                                    updateState({ lot_space: inputValue });
                                                }
                                            }}
                                        />
                                    </div>
                                    {/* <Form.Label>Internal <br /> Accounting ID</Form.Label>
                                    <div className="InputDollorWrap w-25 ms-3">
                                        <input className="form-control InputStyle" type="text"
                                            name="internalAccountingId"
                                            value={state?.internalAccountingId}
                                            onClick={clearerr}
                                            onChange={(e) => updateState({ internalAccountingId: e?.target?.value })}
                                        />
                                    </div> */}
                                </div>
                                <label className="form-label">Enforcement Options<span className="mandatory Error">*</span></label>
                                <div className="d-flex align-item-center holding-feels">
                                    <div className="tagging-types form-control">

                                        <div className="tagging-checkbox">
                                            <input
                                                type="checkbox"
                                                value="mailIn"
                                                onClick={clearerr}
                                                id="weekday-mon-0"
                                                className="weekday"
                                                onChange={handleChangeCheckbox}
                                            />
                                            <label for="weekday-mon-0"></label>
                                        </div>
                                        <p>Mail-in</p>
                                    </div>
                                    <div className="tagging-types form-control">
                                        <div className="tagging-checkbox">
                                            <input
                                                type="checkbox"
                                                value="enforcement_lite"
                                                onClick={clearerr}
                                                id="weekday-mon-0"
                                                className="weekday"
                                                onChange={handleChangeCheckbox}
                                            />
                                            <label for="weekday-mon-0"></label>
                                        </div>
                                        <p>Enforcement Lite</p>
                                    </div>
                                    <div className="tagging-types form-control">
                                        <div className="tagging-checkbox">
                                            <input
                                                type="checkbox"
                                                value="tagVehicle"
                                                id="weekday-mon-0"
                                                onClick={clearerr}
                                                className="weekday"
                                                onChange={handleChangeCheckbox}
                                            />
                                            <label for="weekday-mon-0"></label>
                                        </div>
                                        <p>Tag Vehicle</p>
                                    </div>
                                </div>
                                {taggingType_blank_error && <p className="Error">{taggingType_blank_error}</p>}
                                <div className="offence-block mt-3">
                                    <label className="form-label">Notices<span className="mandatory Error">*</span></label>
                                    <button type="button" className="offence-btn  form-control" onClick={() => setPageState({ isOffenceShow: !pageState?.isOffenceShow })}>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="add-offiences">

                                                Select notices

                                                {/* {returnShotLotName(
                                                    pageState?.selectedOffences,
                                                    // state.selectedOffences,
                                                    20,
                                                    true,
                                                    "offenceName"
                                                )} */}

                                            </div>
                                            <span className="dropdown-arrow"></span>
                                        </div>
                                    </button>

                                    {pageState?.['isOffenceShow'] &&
                                        <div className="paymentWrapper" onClick={() => setPageState({ isOffenceShow: false })}>
                                            <div>
                                                <div className="paymentVendorblock" onClick={e => e.stopPropagation()}>
                                                    <img
                                                        src={close}
                                                        onClick={() => {
                                                            setPageState({ isOffenceShow: false })
                                                        }}
                                                        className="close-btn"
                                                    />
                                                    <ul className="Offencelist">
                                                        {
                                                            pageState['offences']?.map((offence, index) => {
                                                                const isSelected = pageState?.selectedOffences?.indexOf(offence) !== -1;
                                                                return (
                                                                    <li key={index} onClick={() => {
                                                                        handleOffences(offence)
                                                                        clearerr()
                                                                    }}>

                                                                        <div>{offence?.['offenceName']}</div>
                                                                        <div className="vendor-checkbox">
                                                                            <input
                                                                                type="checkbox" id={`offence-${index}`} className="weekday"
                                                                                checked={
                                                                                    isSelected
                                                                                }
                                                                            />

                                                                            <label htmlFor={`offence-${index}`}></label>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    }


                                    <div className="show-offences">
                                        {pageState.selectedOffences?.map(offence => {
                                            console.log({ offence })
                                            return (
                                                <h5>
                                                    <img src={checkbox} />
                                                    {offence?.offenceName}
                                                </h5>
                                            )
                                        }
                                        )}
                                    </div>


                                </div>

                                {selectedOffences_blank_error && <p className="Error">Please select notice</p>}
                                <label className="form-label mt-3">Select Time Zone<span className="mandatory Error"></span></label>
                                <Select
                                  id="timezone-select"
                                  options={americanTimeZones.map((zone) => ({
                                    value: zone,
                                    label: zone,
                                  }))}
                                  value={americanTimeZones.find((option) => option.value === selectedTimeZone)}
                                  onChange={handleChange}
                                  placeholder="Select Time Zone"
                                  isClearable
                                  menuPlacement="top"
                                />
                                {isDev && <Form.Label className="mt-3">PMC location Id</Form.Label>}
                                {isDev && <Form.Control
                                    type="text"
                                    placeholder="PMC Location Id"
                                    value={pmc_locationId}
                                    autoComplete="off"
                                    onClick={clearerr}
                                    onChange={(e) => {
                                        const regex = /^[0-9\b]+$/;

                                            setpmc_locationId(e.target.value)
                                            seterrmessagepmc_locationId(false)

                                            if (e.target.value?.length === 1) {
                                                // setpmc_locationId()
                                            }
                                            // alert("Text are not allowed")
                                            // seterrmessagepmc_locationId("Text are not allowed")
                                            // set_pmc_locationId_blank_error(false)
                                        // }
                                    }}

                                />}
                                {isDev && errmessagepmc_locationId && <p className="Error">{errmessagepmc_locationId}</p>}
                                {isDev && pmc_locationId_blank_error && <p className="Error">{pmc_locationId_blank_error}</p>}
                                {isDev && <Form.Label className="mt-3">Spot Hero Id</Form.Label>}
                                {isDev && <Form.Control
                                    type="text"
                                    placeholder="Facility Id"
                                    value={facilityId}
                                    maxLength={8}
                                    autoComplete="off"
                                    onClick={clearerr}
                                    onChange={(e) => {
                                        const regex = /^[0-9\b]+$/;
                                        if (e.target.value === '' || regex.test(e.target.value)) {
                                            setfacilityId(e.target.value)
                                            seterrmessagefacilityId(false)
                                        } else {
                                            if (e.target.value?.length === 1) {
                                                setfacilityId()
                                            }
                                            // alert("Text are not allowed")
                                            seterrmessagefacilityId("Text are not allowed")
                                            set_facilityId_blank_error(false)
                                        }
                                    }}

                                />}
                                {isDev && errmessagefacilityId && <p className="Error">{errmessagefacilityId}</p>}
                                {isDev && facilityId_blank_error && <p className="Error">{facilityId_blank_error}</p>}
                                {isDev && <Form.Label className="mt-3">Pro Park Id</Form.Label>}
                                {isDev && <Form.Control
                                    type="text"
                                    placeholder="Pro Park Id"
                                    value={proParkId}
                                    maxLength={8}
                                    autoComplete="off"
                                    onClick={clearerr}
                                    onChange={(e) => {
                                        setProParkId(e.target.value)
                                        seterrmessageproParkId(false)
                                    }}

                                />}
                                {isDev && errmessageproParkId && <p className="Error">{errmessageproParkId}</p>}
                                {isDev && proParkId_blank_error && <p className="Error">{proParkId_blank_error}</p>}

                            </Form.Group>

                            <Form.Group
                                className="col-12"
                                controlId="exampleForm.ControlTextarea1"
                            >
                                <button
                                    type="submit"
                                    className="btn DarkBtn"
                                // onClick={(e) => onAdd()}
                                >
                                    {isEditLot?.edit ? "Save" : "Add"}
                                </button>
                            </Form.Group>
                        </Form>
                    </div>
                </div>

            </section >

        </>
    );
}

import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import AdminHeader from "../Citations-Header/container";
import OperatorHeader from "../../../components/common/Header/container";
import { useDispatch } from "react-redux";
import searchicon from "../../../images/search.svg";
import leftArrow from "../../../images/left-arrow.svg";
import { setAlert, setLoading } from "../../../store/actioncreator";
import { useLocation, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { capitalize } from "@mui/material";
import { Pagination } from "antd";
import { STATUS_CLASSES } from "../../../constants/phase";
import Refund_modal from "../../../utils/refund_modal";
import { debounce } from "../../../global";

function RefundRequest(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchInputRef = useRef();
  const [searchKey, setSearchKey] = useState("");
  const [userRole, setUserRole] = useState(null);
  const [reason, setReason] = useState("");
  const [refund_role, setrefund_role] = useState("admin");
  const [taggrFee, setTaggrFee] = useState(false);
  console.log(userRole, "<<==userrole")
  const initialPageState = {
    total: 0,
    defaultCurrentPage: 1,
    currentPage: 1,
    pageSize: 10,
    showModal: false,
    refund_citation_id: null,
    void_citation_id: null,
    refund_popup_title: "",
    header_title: "Refund",
    operator_name: "",
    amount: 0,
    citation_type: null,
    refund_type: null,
    refundRequests: [],
    requestId: null,
    status: null
  };
  const [pageState, setPageState] = useReducer((prev, next) => {
    if (next?.currentPage) {
      getAllRefundRequests(next?.currentPage);
    }
    return { ...prev, ...next };
  }, initialPageState);

  const handleActionState = (val) => {
    let obj = val?.requestType?.split("_")?.[1] === "void" 
    ? { 
      void_citation_id: val?.citation?._id,
      header_title: "Void",
      amount: val?.citation?.canRefundAmount?.toFixed(2),
      }
    : { 
      refund_citation_id: val?.citation?._id,
      amount: val?.requestedAmount,
      maximumRefundAmount: val?.citation?.calculative_amount > 55 ? (val?.citation?.calculative_amount - 55)?.toFixed(2) : 0,
      fullRefundAmount: val?.citation?.canRefundAmount
    }
    setPageState({
      ...obj,
      citation_type: val?.citation?.citation_type,
      refund_type: val?.requestRefundType,
      requestId: val?._id,
      operator_name: val?.user?.fullname,
      refund_popup_title:
        val?.citation?.citation_number,
      showModal: true,
      status: "approved"
    });
    setTaggrFee(val?.requestType?.split("_")?.[1] === "void" ? val?.withholdTaggrFee : false)
    setReason(val?.reason);
  }

  const columns = [
    {
      field: "citation",
      headerName: "Notice Number",
      width: 200,
      hideable: false,
      editable: false,
      valueGetter: (val) => (val?.citation_number ?? ''),
    },
    {
      field: "canRefundAmount",
      headerName: "Can refund amount($)",
      width: 150,
      valueGetter: (val, row) => row?.requestType === "citation_void" ? "" : row?.citation?.canRefundAmount?.toFixed(2),
    },
    {
      field: "requestedAmount",
      headerName: "Requested amount($)",
      width: 150,
      valueGetter: (val, row) => row?.requestType === "citation_void" ? "" : val?.toFixed(2),
    },
    {
      field: "requestRefundType",
      headerName: "Refund Type",
      width: 180,
      renderCell: (row) => {
        let val = row?.row;
        return <td align="center">{capitalize(val?.requestRefundType || "")}</td>;
      },
    },
    {
      field: "requestType",
      headerName: "Request Type",
      width: 180,
      renderCell: (row) => {
        let val = row?.row;
        return <td align="center"><strong>{capitalize(val?.requestType?.split("_")?.[1] || "Refund")}</strong></td>;
      },
    },
    {
      field: "requestStatus",
      headerName: "Request status",
      width: 180,
      renderCell: (row) => {
        let val = row?.row;
        return (
          <td align="center">
            <span className={STATUS_CLASSES[val?.requestStatus] || "red"}>
              {capitalize(val?.requestStatus)}
            </span>
          </td>
        );
      },
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 150,
      valueGetter: (val) => val,
    },
    {
      field: "user",
      headerName: "Requested by",
      width: 150,
      valueGetter: (val) => val?.fullname,
    },
   
  ];

  if (userRole === "superAdmin") {
    columns?.push(
      {
        field: "b",
        headerName: "Actions",
        width: 300,
        renderCell: (val, row) => {
          var val = val?.row;
          return (
            <div className="d-flex align-items-center">
              <button
                className={
                  val?.requestStatus === "pending"
                    ? "DarkBlackBtn mt-2"
                    : "RefundBtnDisbled mt-2"
                }
                style={{ width: "65px" }}
                disabled={val?.requestStatus !== "pending"}
                onClick={() => {
                  if (val?.requestStatus === "pending") {
                    handleActionState(val)
                  }
                }}
              >
                Accept
              </button>
              <button
                className={
                  val?.requestStatus === "pending"
                    ? "DarkBlackBtn mt-2"
                    : "RefundBtnDisbled mt-2"
                }
                style={{ width: "65px" }}
                disabled={val?.requestStatus !== "pending"}
                onClick={() => {
                  if (val?.requestStatus === "pending") {
                    handleRequestStatus( val?._id, 'rejected')
                  }
                }}
              >
                Reject
              </button>
              <button
                className={
                  val?.requestStatus !== "rejected"
                    ? "RefundBtnDisbled mt-2"
                    : "DarkBlackBtn mt-2"
                }
                style={{ width: "65px" }}
                disabled={val?.requestStatus !== "rejected"}
                onClick={() => {
                  if (val?.requestStatus === "rejected") {
                    handleRequestStatus( val?._id, 'pending')
                  }
                }}
              >
                Reopen
              </button>
            </div>
          );
        },
      },
    )
  }

  const handleModalClose = () => {
    setReason("");
    setrefund_role("admin");
    setTaggrFee(false);
    setPageState({ showModal: false });
  };

  const fetchMe = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      const { value } = await props.user();
      if (value?.success) {
        value?.user?.role !== "superAdmin" &&
          value?.user?.role !== "operator" &&
          value?.user?.role !== "manager" &&
          navigate("/dashboard");
        setUserRole(value?.user?.role);
      } else {
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
  }, [location?.pathname]);

  useEffect(() => {
    fetchMe();
    getAllRefundRequests(1);
    // eslint-disable-next-line
  }, []);

  async function handleRequestStatus(requestId, status){
    try {
      const {value} = await props.approved_refund_request(
        requestId?.length > 0 ? requestId: pageState?.requestId,
        status?.length > 0 ? status : pageState?.status
      );
      if(value?.success){
        getAllRefundRequests(pageState?.currentPage);
        dispatch(setAlert("success", value?.message));
      }
      else{
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
  }
  async function getAllRefundRequests(page) {
    console.log(page, "<===page")
    try {
      const { value } = await props.get_all_refund_requests({ page, size: 10 });
      if (value?.success) {
        setPageState({
          refundRequests: value?.refundRequests,
          total: value?.total,
        });
      } else {
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
    dispatch(setLoading(false));
  }

  const onVoidModalSubmit = async () => {
    dispatch(setLoading(true));
    let err;
    if (!reason || reason?.trim()?.length <= 0) {
      err = true;
      dispatch(setAlert("error", "Reason can't be empty!"));
    }

    if (!err) {
      setPageState({ showModal: false });
      try {
        const { value } = await props.void_citation(
          pageState?.void_citation_id,
          reason,
          taggrFee
        );
        if (value?.success) {
          await props.approved_refund_request(pageState?.requestId, pageState?.status);
          getAllRefundRequests(pageState?.currentPage);
          dispatch(setAlert("success", value?.message));
        } else {
          dispatch(setAlert("error", value?.message));
        }
      } catch (error) {
        dispatch(setAlert("error", error?.message));
      }
    }
    handleModalClose();
    dispatch(setLoading(false));
  };

  const onRefundModalSubmit = async () => {
    dispatch(setLoading(true));
    let err;
    if (!reason || reason?.trim()?.length <= 0) {
      err = true;
      dispatch(setAlert("error", "Reason can't be empty!"));
    }
    if(pageState?.amount < 1){
      err = true;
      dispatch(setAlert("error", "Invalid amount entered!"));
    }
    // Amount must be greater than the taggr's amount
    if (parseFloat(pageState?.amount) > parseFloat(pageState?.maximumRefundAmount) && pageState?.amount !== pageState?.fullRefundAmount) {
      err = true;
      dispatch(setAlert("error", "Invalid amount entered!"));
    }
    
    if (refund_role === undefined) {
      err = true;
      dispatch(setAlert("error", "Please select Issued by!"));
    }

    if (!err) {
      setPageState({ showModal: false });
      try {
        const { value } = await props.refund_citation(
          pageState?.refund_citation_id,
          reason,
          refund_role,
          taggrFee,
          pageState?.amount
        );
        // const value = {success: true, message: "Refund successfully!"}
        if (value?.success) {
          await props.approved_refund_request(pageState?.requestId, pageState?.status);
          getAllRefundRequests(1);
          dispatch(setAlert("success", value?.message));
        } else {
          dispatch(setAlert("error", value?.message));
        }
      } catch (error) {
        dispatch(setAlert("error", error?.message));
      }
    }
    handleModalClose();
    dispatch(setLoading(false));
  };

  async function search_refund_request(query) {
    try {
      if (query?.length > 2) {
        const { value } = await props?.search_refund_request(userRole === "superAdmin" ? "refund_request_admin" : "refund_request_operator", query)
        if (value?.success) {
          setPageState({
            refundRequests: value?.results,
            total: 0,
          });
        }
        else {
          dispatch(setAlert('error', value?.message))
        }
      }
    } catch (error) {
      dispatch(setAlert('error', error?.message))
    }
  }

  return (
    <>
      <section className="adminCitations">
        {userRole &&
          (userRole === "superAdmin" ? <AdminHeader /> : <OperatorHeader />)}
        <div className="citationsSection paddzero" id="profile-wrapper">
          <div className="title-wrapper">
            <div className="d-flex align-items-center">
              <img
                src={leftArrow}
                className="left-arrow"
                onClick={() => navigate("/taggr_activities")}
              />
              <h4>Refund/Void Requests</h4>
            </div>
            <div className="search-input">
              <button>
                <img src={searchicon} />
              </button>
              <input
                type="search"
                placeholder="Search..."
                ref={searchInputRef}
                onChange={async (e) => {
                  setSearchKey(e?.target?.value);
                  e?.target?.value?.length > 2 &&
                   debounce(async() => await search_refund_request(e?.target?.value),
                      500
                    );
                  e?.target?.value?.length === 0 && getAllRefundRequests(1);
                }}
              />
            </div>
          </div>
          <div className="cirtation-wrapper">
            <div className="table-responsive">
              <DataGrid
                rows={
                  pageState?.refundRequests?.length > 0
                    ? pageState?.refundRequests?.map(
                        (refundRequest) => refundRequest
                      )
                    : []
                }
                columns={columns}
                pageSize={pageState.pageSize}
                rowsPerPageOptions={[10]}
                hideFooter
                pagination
                onPageChange={(params) =>
                  setPageState({ currentPage: params.page })
                }
                rowCount={pageState?.total}
                paginationMode="server"
                loading={props.loading}
                getRowId={(row) => {
                  console.log(row._id, "<<<id");
                  return row._id;
                }}
                localeText={{
                  noRowsLabel: 'No record found', 
                }}
              />
            </div>
          </div>
          <div className="pagination-block">
            <Pagination
              hideOnSinglePage
              defaultCurrent={pageState?.defaultCurrentPage}
              onChange={(page) => setPageState({ currentPage: page })}
              current={pageState?.currentPage}
              pageSize={pageState?.pageSize}
              total={pageState?.total}
              showSizeChanger={false}
            />
          </div>
        </div>
      </section>
      <Refund_modal
        show={pageState?.showModal}
        // onClose={() => setPageState({ showModal: false })}
        onClose={handleModalClose}
        setReason={(e) => setReason(e?.target?.value)}
        amount={pageState?.amount}
        maximumRefundAmount={pageState?.maximumRefundAmount}
        fullRefundAmount={pageState?.fullRefundAmount}
        setAmount={(amount) => setPageState({ amount })}
        reason={reason}
        setrefund_role={setrefund_role}
        refund_role={refund_role}
        setTaggrFee={setTaggrFee}
        taggrFee={taggrFee}
        onSubmit={pageState?.citation_type === "tag_vehicle" 
          ? onRefundModalSubmit
          : onVoidModalSubmit
        }
        title={pageState?.refund_popup_title}
        header_title={pageState?.header_title}
        operator_name={pageState?.operator_name}
        citation_type={pageState?.citation_type}
        refund_type={pageState?.refund_type}
      />
    </>
  );
}

export default RefundRequest;

import React, { useRef } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import searchicon from "../../../images/search.svg";
import location from "../../../../src/images/location.svg";
import Header from "../Citations-Header/container";
import { useEffect, useState } from 'react';
import { setAlert } from "../../../store/actioncreator";
import { useDispatch } from "react-redux";
import { debounce } from "../../../global";
import { setLoading } from "../../../store/actioncreator";

// import { useSelector } from "react-redux";


export default function DashboardComponent(props) {


  // const loadingState = useSelector(state => state?.loading);
  // const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const [name, setName] = useState()
  const [user, setUser] = useState({ users: [] })
  const [uservendor, setUservendor] = useState({ users: [] })
  // const [img, setImg] = useState();

  const [searchKey, setSearchKey] = useState('');
  const searchInputRef = useRef()

  const dispatch = useDispatch()

  useEffect(() => {  
    fetchName();
    fetchusers("operator");
    fetchvendors("vendor");
    statisticsadmin();
  }, [])

  const fetchusers = async (role) => {
    dispatch(setLoading(true))
    try {
      const { value } = await props.getusers(role)
      if (value?.success) {
        setUser(value)
      } else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message))
    }
    dispatch(setLoading(false))
  }

  const search = async query => {
    try {
      if (query?.length > 2) {
        const { value } = await props?.searchForOperator('operator', query)
        if (value?.success) {
          setUser({ users: value?.results })          
        }
        else {
          dispatch(setAlert('error', value?.message))
        }
      }
    } catch (error) {
      dispatch(setAlert('error', error?.message))
    }
  }



  const fetchvendors = async (role) => {
    dispatch(setLoading(true))
    const { value } = await props.getusers(role)
    try {
      if (value?.success) {
        setUservendor(value)
        // setImg(value?.users[0]?.avatar)
      } else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message))
    }
    dispatch(setLoading(false))
  }
  const searchVendor = async query => {
    try {
      if (query?.length > 2) {
        const { value } = await props?.searchForOperator('vendor', query)
        if (value?.success) {
          setUservendor({ users: value?.results })
        }
        else {
          dispatch(setAlert('error', value?.message))
        }
      }
    } catch (error) {
      dispatch(setAlert('error', error?.message))
    }
  }


  const fetchName = async () => {
    const { value } = await props.user()
    if (value?.success) {
      setName(value)
    }

  }

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
  }


  const [statisticsdata, setstatisticsdata] = useState()

  const statisticsadmin = async () => {
    try {
      const { value } = await props.statistics_admin();
      if (value?.success) {
        setstatisticsdata(value);
      }
    } catch (error) {
      console.log(error.message, "<<<user error");
    }
  };


  return (
    <>
      <section className="adminDashboard">
        <Header />

        <div className="DashboardSection" id="profile-wrapper">
          <div className="leftSection">
            <div className="title">
              <h4>Key Statistics</h4>
            </div>
            <div className="statsblock">
              <ul>
                <li className="row">
                  <div className="col">
                    <div className="stats-list">
                      <h1>{statisticsdata?.statistics?.active_taggrs ?? 0}</h1>
                      <p>Active taggrs</p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="stats-list">
                      <h1>{statisticsdata?.statistics?.active_lots ?? 0}</h1>
                      <p>Active Lots</p>
                    </div>
                  </div>
                </li>
                <li className="row">
                  <div className="col">
                    <div className="stats-list">
                      <h1>{statisticsdata?.statistics?.tags_deployed ?? 0}</h1>
                      <p>Tags Deployed</p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="stats-list">
                      <h1>{statisticsdata?.statistics?.mail_in_tags_deployed ?? 0}</h1>
                      <p>Mail_in Tags Deployed</p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="stats-list">
                      <h1>${statisticsdata?.statistics?.payout?.taggr ?? 0}</h1>
                      <p>Taggrs Payout</p>
                    </div>
                  </div>
                </li>
                <li className="row">
                  <div className="col">
                    <div className="stats-list">
                      <h1>${statisticsdata?.statistics?.payout?.operator ?? 0}</h1>
                      <p>Operators Payout</p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="stats-list">
                      <h1>${statisticsdata?.statistics?.payout?.tire_tag ?? 0}</h1>
                      <p>Tire Tag Payout</p>
                    </div>
                  </div>
                </li>
                <li className="row">
                  <div className="col">
                    <div className="stats-list">
                      <h1>${statisticsdata?.statistics?.mail_in_payout?.taggr ?? 0}</h1>
                      <p>Mail_in Taggr's Payout</p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="stats-list">
                      <h1>${statisticsdata?.statistics?.mail_in_payout?.tire_tag ?? 0}</h1>
                      <p> Mail_in Tire Tag Payout</p>
                    </div>
                  </div>
                </li>
                <li className="row">
                  <div className="col">
                    <div className="stats-list">
                      <h1>${statisticsdata?.statistics?.mail_in_payout?.operator ?? 0}</h1>
                      <p>Mail_in Operators Payout</p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="stats-list">
                      <h1>${statisticsdata?.statistics?.mail_in_payout?.taggr_solutions ?? 0}</h1>
                      <p>Mail_in Taggr Solutions Payout</p>
                    </div>
                  </div>
                </li>
                <li className="row">
                  <div className="col">
                    <div className="stats-list">
                      <h1>${statisticsdata?.statistics?.payout?.taggr_solutions ?? 0}</h1>
                      <p>Taggr Solutions Revenue</p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="stats-list">
                      <h1>${statisticsdata?.statistics?.payout?.total_revenue ?? 0}</h1>
                      <p>Total Revenue</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="rightSection">
            <div className="d-flex justify-content-between align-item-center">
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" data-bs-toggle="tab" href="#home">Operators</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#menu1" >1099</a>
                </li>
              </ul>
            </div>

            <div className="tab-content">
              <div id="home" className="tab-pane active">
                <div className="search-input">

                  <button>
                    <img src={searchicon} />
                  </button>

                  <input
                    type="search"
                    placeholder="Search..."
                    ref={searchInputRef}
                    onChange={async (e) => {
                      setSearchKey(e?.target?.value)
                      e?.target?.value?.length > 2 && debounce(async() => await search(e?.target?.value), 500)
                      e?.target?.value?.length === 0 && fetchusers("operator")
                    }}
                  />
                </div>
               
                <ul className="operator-block">
                  {
                    user?.users.length > 0 ? (
                      user?.users.map((value) => {
                        return (
                          <li key={value._id}>
                           <Link
                            to={{
                              pathname: `/operator/${value?._id}`,
                            }}
                          >
                            <div className="logo-img">
                             
                              <img src={value?.avatar} />
                            </div>
                            <div className="operator-list">

                              <h5>{value?.fullname}</h5>

                              
                              <p>
                                {value?.address && <img src={location} />}
                                {value?.address ? value?.address : ''}
                              </p>

                            </div>
                          </Link>
                          </li>
                        )
                      })
                    ) 
                    : 
                    // (
                    //   searchKey && <h5>No results found.</h5>
                    //  ) 
                    (
                      user === null || user?.users.length === 0 ?
                        searchKey && 
                        <div className="dataText">
                          <h5>No Result Found.</h5>
                        </div>
                        :
                        <div className="dataText">
                        <h5>No Result Found.</h5>
                      </div>
                    )
                  }


                </ul>

              </div>
              <div id="menu1" className="tab-pane fade">
                <div className="search-input">
                  <button> <img src={searchicon} /></button>
                  <input
                    type="search"
                    placeholder="Search..."
                    onChange={async (e) => {
                      e?.target?.value?.length > 2 && debounce(async() => await searchVendor(e?.target?.value), 500)
                      e?.target?.value?.length === 0 && fetchvendors("vendor");
                    }}
                  />
                </div>
                <ul className="operator-block">
                  {

                 uservendor?.users.length > 0 ? (

                    uservendor?.users.map((value) => {
                      return (
                        <li>
                          {/* <a href="/taggr"> */}
                          <Link
                            to={{
                              pathname: `/taggr/${value?._id}`,
                            }}
                          >
                            <div className="logo-img">
                              <img src={value?.avatar} />
                            </div>
                            <div className="operator-list">
                              <h5>{value?.fullname}</h5>
                              <p>Email : {value?.email}</p>
                            </div>
                          </Link>
                          {/* </a> */}
                        </li>
                      )
                    })
                  
                   ) : (
                    <li>
                  <div className="dataText">
                  <h5>No Result Found.</h5>
                  </div>
                    </li>
                  )
                }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

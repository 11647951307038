import React, { useEffect, useReducer, useRef, useState } from "react";
import { Link } from "react-router-dom";
import AdminHeader from "../Citations-Header/container";
import searchicon from "../../../images/search.svg";
import leftArrow from "../../../images/left-arrow.svg";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { capitalize } from "@mui/material";
import { Pagination } from "antd";
import { debounce } from "../../../global";
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../../../store/actioncreator";
import DarkView from "../../../images/DarkViewImg.svg";
import WhiteView from "../../../images/WhiteViewImg.svg";
import RefundPopup from "../../../utils/equipmentRefundPopup";
import { STATUS_CLASSES } from "../../../constants/phase";

const pageSize = 10;
function EquipmentFee(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchInputRef = useRef();
  const [equipments, setEquipments] = useState([]);
  const [query, setQuery] = useState(null)
  const initialPageState = {
    total: 0,
    defaultCurrentPage: 1,
    currentPage: 1,
    showModal: false,
    paymentId: null,
    citationId: null,
    receiptNumber: null,
    reason: "",
    amount: 0,
  };
  const [pageState, setPageState] = useReducer((prev, next) => {
    if (next?.currentPage) {
      getEquipments(next?.currentPage);
    }
    return { ...prev, ...next };
  }, initialPageState);
  
  const onClose = () => {
    setPageState({
      paymentId: null,
      citationId: null,
      receiptNumber: null,
      reason: "",
      amount: 0,
      showModal: false
    })
  }

  async function getEquipments(currentPage=1, q=null) {
    dispatch(setLoading(true))
    try {
      const { value } = await props.getEquipmentCharges(currentPage, pageSize || 10, q === true ? null : q || query);
      if (value?.success) {
        setEquipments(value?.equipments);
        setPageState({total: value?.total});
      } else {
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
    dispatch(setLoading(false))
  }

  useEffect(() => {
    getEquipments();
  }, []);
  
  const columns = [
    {
      field: "violator_name",
      headerName: "Violator Name",
      width: 200,
      hideable: false,
      editable: false,
      valueGetter: (val) => val ?? "-",
    },
    {
      field: "email",
      headerName: "Voilator Email",
      width: 200,
      hideable: false,
      editable: false,
      valueGetter: (val) => val ?? "-",
    },
    {
      field: "phone_number",
      headerName: "Voilator Phone No.",
      width: 200,
      hideable: false,
      editable: false,
      valueGetter: (val) => val ?? "-",
    },
    {
      field: "receipt_number",
      headerName: "Receipt No.",
      width: 200,
      hideable: false,
      editable: false,
      valueGetter: (val) => val ?? "-",
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 200,
      hideable: false,
      editable: false,
      valueGetter: (val) => val ?? "-",
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      hideable: false,
      editable: false,
      renderCell: (row) => {
        var val = row?.row;
        return  <td align="center">
          <span className={STATUS_CLASSES[val?.status] || 'red'}>
            {capitalize(val?.status ?? "")}
          </span>
        </td>
      }
    },
    {
      field: "citationNumber",
      headerName: "Citation No.",
      width: 200,
      hideable: false,
      editable: false,
      valueGetter: (val) => val ?? "-",
    },
    {
      field: "lotAddress",
      headerName: "Lot Address",
      width: 200,
      hideable: false,
      editable: false,
      valueGetter: (val) => val ?? "-",
    },
    {
      field: "b",
      headerName: "Actions",
      width: 400,
      height: 400,
      renderCell: (row) => {
        var val = row?.row;
        return (
          <div className="d-flex align-items-center">
            {val?.status === "succeeded" ? (
              <button
                className="DarkBlackBtn"
                style={{ width: "30px" }}
                onClick={() => navigate(`/admin/equipment-receipt/${val?.citation_id}`)}
              >
                🖨️
              </button>
            ) : (
              <button
                className="DarkEditBtnDisbled"
                style={{ width: "30px" }}
                disabled
              >
                🖨️
              </button>
            )}
            <button
              disabled={val?.status === "refunded"}
              className={val?.status === "refunded"? "DarkEditBtnDisbled DarkBlackBtn" : "DarkBlackBtn"}
              style={{ width: "65px" }}
              onClick={() => val?.status !== "refunded" ? setPageState({
                amount: val?.amount,
                showModal: true,
                paymentId: val?._id,
                citationId: val?.citation_id,
                receiptNumber: val?.receipt_number,
              }): ""}
            >
              Refund
            </button>
          </div>
        );
      },
    },
  ];

  const onSubmit = async () => {
    dispatch(setLoading(true))
    try {
      const {value} = await props?.refundEquipment(
        pageState?.citationId,
        pageState?.paymentId,
        pageState?.reason,
        pageState?.amount
      )
      if(value?.success){
        onClose()
        dispatch(setAlert("success", value?.message))
        getEquipments(pageState?.currentPage);
      }
      else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message))
    }
    dispatch(setLoading(false))
  }

  return (
    <>
      <section className="adminCitations">
        <AdminHeader />
        <div className="citationsSection paddzero" id="profile-wrapper">
          <div className="title-wrapper">
            <div className="d-flex align-items-center">
              <img
                src={leftArrow}
                className="left-arrow"
                onClick={() => navigate("/taggr_activities")}
              />
              <h4>Equipment Fee</h4>
            </div>
            <div className="search-input">
              <button>
                <img src={searchicon} />
              </button>
              <input
                type="search"
                placeholder="Type Email or Phone..."
                ref={searchInputRef}
                onChange={async (e) => {
                  let query = e?.target?.value
                  console.log(query, "<==clear")
                  setQuery(query)
                  query?.length > 0 
                  ? debounce(await getEquipments(1, query), 2000)
                  : getEquipments(1, true);
                }}
              />
            </div>
          </div>
          <div className="cirtation-wrapper">
            <div className="table-responsive">
              {equipments?.length > 0 && (
                <DataGrid
                  rows={
                    equipments?.length > 0
                      ? equipments?.map((equipment) => equipment)
                      : []
                  }
                  columns={columns}
                  pageSize={pageSize}
                  rowsPerPageOptions={[10]}
                  hideFooter
                  pagination
                  onPageChange={(params) =>
                    setPageState({ currentPage: params.page })
                  }
                  rowCount={pageState?.total}
                  paginationMode="server"
                  getRowId={(row) => {
                    console.log(row?._id);
                    return row._id;
                  }}
                />
              )}
            </div>
          </div>
          <div className="pagination-block">
            <Pagination
              hideOnSinglePage
              defaultCurrent={pageState?.defaultCurrentPage}
              onChange={(page) => setPageState({ currentPage: page })}
              current={pageState?.currentPage}
              pageSize={pageSize}
              total={pageState?.total}
              showSizeChanger={false}
            />
          </div>
        </div>
      </section>
      <RefundPopup 
        headerTitle="Refund Equipment Charge"
        show={pageState?.showModal} 
        onClose={onClose} 
        receiptNumber={pageState?.receiptNumber}
        amount={pageState?.amount}
        reason={pageState?.reason} 
        setReason={(text) => setPageState({reason: text})} 
        onSubmit={onSubmit}
      />
    </>
  );
}

export default EquipmentFee;

import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import leftArrow from "../../../images/left-arrow.svg";
import payoutleftArrow from "../../../images/payout-left-arrow.svg";
import payoutrightArrow from "../../../images/payout-right-arrow.svg";
import DetailIcon from "../../../images/detailIcon.svg";
import { Link } from "react-router-dom";
import { setAlert, setLoading } from "../../../store/actioncreator";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Pagination } from 'antd';
import moment from "moment-timezone"
import DarkView from "../../../images/DarkViewImg.svg";
import WhiteView from "../../../images/WhiteViewImg.svg";
import { DataGrid } from "@mui/x-data-grid";
import { capitalize } from "@mui/material";
import { Two_decimals } from "../../../global";
import OperatorHeader from '../../../components/common/Header/container';


export default function Payouts(props) {

    const [isForwardDisable, setForwardDisable] = useState(true)
    const [isBackwardDisable, setBackwardDisable] = useState(false)
    const [role, setRole] = useState("operator")
    const [Id, setId] = useState(null)
    const [data, setdata] = useState([])
    const navigate = useNavigate();
    const tz = moment.tz.guess()
    const dispatch = useDispatch();
    const currentDay_of_newyork = moment().tz("America/New_York").format('dddd');
    const currentHour = moment().tz("America/New_York").hour();

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const totalPages = Math.ceil(data?.users?.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    ///////////////////////////////////////////////////////////


    const isWednesday = currentDay_of_newyork === 'Wednesday' && currentHour >= 6;
    const current_Wednesday = moment().tz("America/New_York").day(3).hour(6).minute(0).second(0);
    const currentDayy = moment().tz("America/New_York").day();
    const daysToSubtract = currentDayy === 3 ? 7 : currentDayy < 3 ? currentDayy + 4 : currentDayy - 3;
    const currentWednesday = moment().tz("America/New_York").subtract(daysToSubtract, 'days').hour(6).minute(0).second(0);
    const [startDate, setStartDate] = useState(isWednesday ? current_Wednesday.toDate() : currentWednesday.toDate());
    const [payoutDates, setPayoutDates] = useState([])
    const [endDate, setendDate] = useState();
    const status_classes ={
    paid: "paid-green",
    pending: "red",
    failed: "red",
    voided: "voided-red"
    }
    const handleForward = () => {
        setBackwardDisable(false)
        let date = endDate;
    
        const endDateStr = endDate;
    
        // Find the index of the string endDate
        const endDateIndex = payoutDates.indexOf(endDateStr);
        if (endDateIndex === 0) {
            setForwardDisable(true)
            // date = new Date();
        }
        else if (endDateIndex === -1) {
            console.log('End date not found in the array.');
        } else {
            const nextDateIndex = endDateIndex - 1;
    
            if (nextDateIndex < payoutDates.length) {
                const newEndDateStr = payoutDates[nextDateIndex];
                date  = moment(newEndDateStr).format('YYYY-MM-DD')
            } else {
                console.log('No next date available.');
            }
        }
        
        // date.setHours(6, 0, 0, 0);  // Set time to 6 AM
    
        setendDate(date);
        // Make API call with updated dates
        fetchvalue(role);
    };

    const handleBackward = () => {
        if(!endDate) return;
        setForwardDisable(false)
        // Convert endDate to a date without time for comparison
        const endDateStr = endDate;
        
        // Check if the endDate is in the payoutDates array
        const endDateIndex = payoutDates.indexOf(endDateStr);
        
        // Initialize date with the default value
        let date = endDate
        // If endDate is not in payoutDates and the date is not equal to the first date
        if(endDateIndex == payoutDates?.length-1){setBackwardDisable(true)}
        else if (endDateIndex === -1) {
            // Do nothing specific for comparison, just set date to the first element
            moment(payoutDates[0]).format('YYYY-MM-DD')
        } else {
            // Calculate the previous date index
            const previousDateIndex = endDateIndex + 1;
    
            if (previousDateIndex >= 0) {
                // Get the previous date from payoutDates
                const newEndDateStr = payoutDates[previousDateIndex];
                date = moment(newEndDateStr).format('YYYY-MM-DD')
            } else {
                console.log('No previous date available.');
            }
        }

        setendDate(date);
        fetchvalue(role);
    };
    

    // ********************************************************************************


    // // const isFriday = currentDay_of_newyork === 'Friday';
    // const isFriday = currentDay_of_newyork === 'Friday' && currentHour >= 6;
    // const current_Friday = moment().tz("America/New_York").day(5).hour(6).minute(0).second(0);
    // const currentDayy = moment().tz("America/New_York").day();
    // const daysToSubtract = currentDayy === 5 ? 7 : currentDayy < 5 ? currentDayy + 2 : currentDayy - 5;
    // const currentFriday = moment().tz("America/New_York").subtract(daysToSubtract, 'days').hour(6).minute(0).second(0);

    // // const [startDate, setStartDate] = useState(currentFriday.toDate());
    // const [startDate, setStartDate] = useState(isFriday ? current_Friday.toDate() : currentFriday.toDate());
    // const [endDate, setendDate] = useState(moment().tz("America/New_York").toDate());

    // const handleBackward = () => {
    //     const currentDate = startDate ? moment(startDate)?.tz("America/New_York") : moment()?.tz("America/New_York").startOf('day').hour(); // Set start date to today at 6 PM
    //     const currentDay = currentDate.day();
    //     const daysToSubtract = currentDay === 5 ? 7 : currentDay < 5 ? currentDay + 2 : currentDay - 5;
    //     const previousFriday = moment(currentDate)?.tz("America/New_York").subtract(daysToSubtract, 'days').hour(6); // Set time to 6 PM
    //     const lastFriday = moment()?.tz("America/New_York").day(-2).hour(6).minute(0); // Get the last Friday before the current date (time set to 6 PM)

    //     // Check if the previousFriday is greater than the lastFriday (cannot be later than the last Friday)
    //     if (previousFriday.isAfter(lastFriday)) {
    //         console.log("11112222", startDate, endDate);
    //         setStartDate(lastFriday.toDate());
    //         setendDate(moment(lastFriday)?.tz("America/New_York").add(7, 'days').hour(6).toDate()); // Set end date to the next Friday at 6 PM
    //     } else if (previousFriday.isAfter(moment()?.tz("America/New_York"))) {
    //         console.log("111133333", startDate, endDate);
    //         // Set the start date to the current date if it's greater
    //         setStartDate(moment()?.tz("America/New_York").toDate());
    //     } else {
    //         console.log("11114444", startDate, endDate);
    //         setStartDate(previousFriday.toDate());
    //         setendDate(moment(previousFriday)?.tz("America/New_York").add(7, 'days').hour(6).toDate()); // Set end date to the next Friday at 6 PM
    //     }

    //     // Make API call with updated dates
    //     fetchvalue(role);
    // };



    // const handleForward = () => {
    //     const currentDate = endDate ? moment(endDate)?.tz("America/New_York") : moment()?.tz("America/New_York").startOf('day').hour(6).minute(0); // Set end date to today at 6 PM
    //     const currentDay = currentDate.day();
    //     const daysToAdd = currentDay === 5 ? 7 : currentDay < 5 ? 5 - currentDay : 12 - currentDay;
    //     const nextFriday = moment(currentDate)?.tz("America/New_York").add(daysToAdd, 'days').hour(6).minute(0).second(0); // Set time to 6 PM
    //     const lastFriday = moment()?.tz("America/New_York").day(-2).hour(6).minute(0).second(0); // Get the last Friday before the current date (time set to 6 PM)

    //     // Check if the nextFriday is greater than the lastFriday (cannot be later than the last Friday)
    //     if (nextFriday.isAfter(lastFriday)) {
    //         setStartDate(isFriday ? current_Friday.toDate() : lastFriday.toDate());
    //         console.log("0000", startDate, endDate, ">>>>>", isFriday, ">>>>>>", current_Friday.toDate());
    //         if (moment()?.tz("America/New_York").toDate() - endDate) {
    //             setendDate(moment()?.tz("America/New_York").toDate() > moment()?.tz("America/New_York").hour(6).minute(0).second(0).toDate() ? moment()?.tz("America/New_York").hour(6).minute(0).second(0).toDate() : moment()?.tz("America/New_York").toDate());
    //         } else {

    //             setendDate(moment(lastFriday)?.tz("America/New_York").add(7, 'days').hour(6).minute(0).second(0).toDate()); // Set end date to the next Friday at 6 PM
    //         }
    //         //   setendDate(moment().toDate()); // Set end date to the next Friday at 6 PM
    //     } else if (nextFriday.isAfter(moment()?.tz("America/New_York"))) {
    //         // Set the end date and start date to the current date if it's greater
    //         setendDate(moment()?.tz("America/New_York").toDate());
    //         setStartDate(moment()?.tz("America/New_York").toDate());
    //         console.log("1");
    //     } else {
    //         console.log("2");
    //         setStartDate(nextFriday.toDate());
    //         setendDate(moment(nextFriday)?.tz("America/New_York").add(7, 'days').hour(6).toDate()); // Set end date to the next Friday at 6 PM
    //     }

    //     // Make API call with updated dates
    //     fetchvalue(role);
    // };




    useEffect(() => {
        fetchvalue(role)
    }, [])

    useEffect(() => {
        fetchvalue(role);
    }, [startDate, endDate, currentPage]);


    async function fetchvalue(role) {
       var data = await props.user();
       setId(data?.value?.user?._id);
        dispatch(setLoading(true))
        try {
            var end_date;
            if(payoutDates?.length == 0)
                {
                    const payout_dates = await props?.get_payout_dates();
                    setPayoutDates(payout_dates?.value[0]?.dates)
                    if(payout_dates?.value?.length > 0)
                        {
                            end_date = moment(payout_dates?.value[0]?.dates[0]).format('YYYY-MM-DD');
                            setendDate(end_date);
                        }
                }
            if (!end_date && !endDate) return null
            const { value } = await props?.payout_reconcilation(role, currentPage, 10, { startDate, endDate: endDate ?? end_date }, Id ?? data?.value?.user?._id);
            if (value?.success) {
                setdata(value)
                // console.log(data?.success, "00000000");
            } else {
                dispatch(setAlert('error', value?.message));
                console.log(value?.message);
            }
        } catch (error) {
            dispatch(setAlert('error', error?.message));
            console.log(error?.message);
        }
        dispatch(setLoading(false))
    }

    const columns = role == 'vendor' ? [
      { 
          field: "fullname",
          headerName: "Full Name",
          width: 200,
          hideable: false,
          editable: false,
          valueFormatter: (val, row) => val,
      },
      { 
        field: "user_amount",
        headerName: "Amount transferred",
        width: 200,
        hideable: false,
        editable: false,
        valueFormatter: (val, row) => '$'+val/100,
      },
      { 
        field: "Amount before transferred",
        headerName: "Amount before transferred",
        width: 200,
        hideable: false,
        editable: false,
        valueFormatter: (val, row) => {
          return'$'+  Two_decimals((row?.user_amount + row?.captured_refunded_amount + row?.break_down_amount?.total_withhold_mail_in_citation_amount)/100)}
       },
      { 
        field: "captured_refunded_amount",
        headerName: "Collected Refund Amount",
        width: 200,
        hideable: false,
        editable: false,
        valueFormatter: (val, row) => '-$'+  Two_decimals(val/100),
       },
      {
        field: "break_down_amount",
        headerName: "Withhold amount (Mail-In)",
        width: 200,
        hideable: false,
        editable: false,
        valueGetter: (val) => '$'+ Two_decimals(val?.total_withhold_mail_in_citation_amount / 100)
      },
      {
        field: "user_destination",
        headerName: "User account",
        width: 150,
        valueFormatter: (val) => (val),
      },
      {
        field: "transfer_group",
        headerName: "Payout transfer Group",
        width: 150,
      },
      // {
      //   field: "tire_tag_amount",
      //   headerName: "Tire Tag Amount",
      //   width: 150,
      //   valueFormatter: (val) => '$'+(val/100)
      // },
      // { field: "taggr_solutions_amount", headerName: "Taggr Solutions Amount", width: 150, valueFormatter: (val) => '$'+(val/100)},
      {
        field: "status",
        headerName: "Status",
        width: 150,
        renderCell: (row) => 
        { var val = row?.row;
        return  <td align="center">
                            <span className={status_classes[val?.status] || 'red'}>
                              {capitalize(val?.status ?? "")}
                            </span>
                          </td>
        },
      },
      {
        field: "user_type",
        headerName: "Type",
        width: 150,
        valueFormatter: (val) => capitalize(val ?? "")
      },
      {
        field: "taggr_tire_tag_paid_citations",
        headerName: "Tire tag Paid citations",
        width: 150,
        valueFormatter: (val, row) =>  val
  
      },
      {
        field: "taggr_paid_mail_in_citation_ids",
        headerName: "Paid Mail in citations",
        width: 150,
        valueFormatter: (val, row) =>  val
      },
      {
        field: "withhold_mail_in_citation_ids",
        headerName: "Withhold Mail in citations",
        width: 150,
        valueFormatter: (val, row) =>  val?.length
      },
      {
        field: "taggr_un_paid_mail_in_citation_ids",
        headerName: "Taggr mail in citations",
        width: 150,
        valueFormatter: (val, row) =>  val
  
      },
      {
        field: "refunded_citation_data",
        headerName: "Refunded citations",
        width: 150,
        valueFormatter: (val, row) =>  val
  
      },
      {
        field: "createdAt",
        headerName: "Created at",
        width: 150,
        valueFormatter: (val, row) =>  moment(val)?.tz(tz).format('YYYY-MM-DD HH:mm:ss')
  
      },
      { field: "b", headerName: "Actions", width: 200, renderCell: (row) => 
        {
          var val = row?.row;
          return <div className="d-flex align-items-center">
           <Link
              to={`/allcitations?payoutId=${val?._id}` + `&role=${role}&start_date=${startDate}&end_date=${endDate}&page=${currentPage}&limit=10`}
              className="eyeBtn DarkBlackBtn mt-2"
              >
              <img src={DarkView} className="DarkImg" />
              <img src={WhiteView} className="WhiteImg" />
            </Link>
            {val?.refunded_citation_data > 0 && <Link
              to={`/refundLogs?payoutId=${val?._id}` + `&role=${role}&start_date=${startDate}&end_date=${endDate}&page=${currentPage}&limit=10&isDev=true`}
              className="eyeBtn DarkBlackBtn mt-2"
              >
              Logs
            </Link>
            }
          </div>
        }
      },
    ] : role == "operator" ? [
      { 
          field: "fullname",
          headerName: "Full Name",
          width: 200,
          hideable: false,
          editable: false,
          valueFormatter: (val, row) => val,
      },
      { 
        field: "user_amount",
        headerName: "Actual amount transferred",
        width: 200,
        hideable: false,
        editable: false,
        valueFormatter: (val, row) => '$'+val/100,
      },
      { 
          field: "Amount before transferred",
          headerName: "Amount before transferred",
          width: 200,
          hideable: false,
          editable: false,
          valueFormatter: (val, row) => '$'+ (row?.original_amount ? row?.original_amount : row?.break_down_amount?.operator_amount_before_MID)/100,
      },
      { 
        field: "dispute",
        headerName: "Collected Dispute Amount",
        width: 200,
        hideable: false,
        editable: false,
        valueFormatter: (val, row) => '-$'+val?.total_operator_amount,
      },
      { 
        field: "captured_refunded_amount",
        headerName: "Collected Refund Amount",
        width: 200,
        hideable: false,
        editable: false,
        valueFormatter: (val) => '-$'+  Two_decimals(val/100),
       },
      { 
          field: "break_down_amount",
          headerName: "Mail in deduction amount",
          width: 200,
          hideable: false,
          editable: false,
          valueFormatter: (val, row) => '-$'+val?.amount_for_MID/100,
      },
      {
        field: "user_destination",
        headerName: "User account",
        width: 150,
        valueFormatter: (val) => (val),
      },
      {
        field: "transfer_group",
        headerName: "Payout transfer Group",
        width: 150,
      },
      // {
      //   field: "tire_tag_amount",
      //   headerName: "Tire Tag Amount",
      //   width: 150,
      //   valueFormatter: (val) => (val/100)
      // },
      // { field: "taggr_solutions_amount", headerName: "Taggr Solutions Amount", width: 150, valueFormatter: (val) => '$'+(val/100)},
      {
        field: "status",
        headerName: "Status",
        width: 150,
        renderCell: (row) => 
        { var val = row?.row;
        return  <td align="center">
                            <span className={status_classes[val?.status] || 'red'}>
                              {capitalize(val?.status ?? "")}
                            </span>
                          </td>
        },
      },
      {
        field: "user_type",
        headerName: "Type",
        width: 150,
        valueFormatter: (val) => capitalize(val ?? "")
      },
      { 
        field: "disputeIds",
        headerName: "Disputed citations",
        width: 150,
        hideable: false,
        editable: false,
        renderCell: (row) => {
          var val = row?.row;
          return <Link
          target="_blank"
          rel="noopener noreferrer"
          to={`/allcitations?payoutId=${val?._id}` + `&role=${role}&start_date=${startDate}&end_date=${endDate}&page=${currentPage}&limit=10&type=DC`}
          className="eyeBtn DarkBlackBtn mt-2"
          >
            {val?.dispute?.disputed_citation_ids ?? 0}
          </Link>
        }
      },
      {
        field: "operator_paid_citations",
        headerName: "Paid citations",
        width: 150,
        renderCell: (row) => {
          var val = row?.row;
          return <Link
          target="_blank"
          rel="noopener noreferrer"
          to={`/allcitations?payoutId=${val?._id}` + `&role=${role}&start_date=${startDate}&end_date=${endDate}&page=${currentPage}&limit=10&type=PC`}
          className="eyeBtn DarkBlackBtn mt-2"
          >
            {val?.operator_paid_citations}
          </Link>
        }
      },
      {
        field: "refunded_citation_data",
        headerName: "Refunded citations",
        width: 150,
        valueFormatter: (val) => val ?? 0
        // renderCell: (row) => {
        //   var val = row?.row;
        //   return <Link
        //   disabled
        //   target="_blank"
        //   rel="noopener noreferrer"
        //   to={`/allcitations?payoutId=${val?._id}` + `&role=${role}&start_date=${startDate}&end_date=${endDate}&page=${currentPage}&limit=10&type=RC`}
        //   className="eyeBtn DarkBlackBtn mt-2"
        //   >
        //     {val?.refunded_citation_data}
        //   </Link>
        // }
      },
      {
        field: "operator_MID_ids",
        headerName: "Taggr Mail in deductions",
        width: 150,
        renderCell: (row) => {
          var val = row?.row;
          return <Link
          target="_blank"
          rel="noopener noreferrer"
          to={`/allcitations?payoutId=${val?._id}` + `&role=${role}&start_date=${startDate}&end_date=${endDate}&page=${currentPage}&limit=10&type=MID`}
          className="eyeBtn DarkBlackBtn mt-2"
          >
            {val?.operator_MID_ids}
          </Link>
        }
      },
      {
        field: "operator_ELD_ids",
        headerName: "Taggr EL deductions",
        width: 150,
        renderCell: (row) => {
          var val = row?.row;
          return <Link
          target="_blank"
          rel="noopener noreferrer"
          to={`/allcitations?payoutId=${val?._id}` + `&role=${role}&start_date=${startDate}&end_date=${endDate}&page=${currentPage}&limit=10&type=ELD`}
          className="eyeBtn DarkBlackBtn mt-2"
          >
            {val?.operator_ELD_ids}
          </Link>
        }
      },
      {
        field: "createdAt",
        headerName: "Created at",
        width: 150,
        valueFormatter: (val, row) => moment(val)?.tz(tz).format('YYYY-MM-DD HH:mm:ss')
  
      },
      { field: "b", headerName: "Actions", width: 200, renderCell: (row) => 
        {
          var val = row?.row;
          return <div className="d-flex align-items-center">
          <Link
              to={`/allcitations?payoutId=${val?._id}` + `&role=${role}&start_date=${startDate}&end_date=${endDate}&page=${currentPage}&limit=10`}
              className="eyeBtn DarkBlackBtn mt-2"
              >
              <img src={DarkView} className="DarkImg" />
              <img src={WhiteView} className="WhiteImg" />
            </Link>
            {val?.refunded_citation_data > 0 && <Link
              to={`/refundLogs?payoutId=${val?._id}` + `&role=${role}&start_date=${startDate}&end_date=${endDate}&page=${currentPage}&limit=10&isDev=true`}
              className="eyeBtn DarkBlackBtn mt-2"
              >
              Logs
            </Link>
            }
              </div>
        }
      },
    ] : [
      { 
          field: "fullname",
          headerName: "Full Name",
          width: 200,
          hideable: false,
          editable: false,
          valueFormatter: (val, row) => val,
      },
      { 
        field: "user_amount",
        headerName: "Amount transferred",
        width: 200,
        hideable: false,
        editable: false,
        valueFormatter: (val, row) => '$'+val/100,
      },
      { 
        field: "Amount before transferred",
        headerName: "Amount before transferred",
        width: 200,
        hideable: false,
        editable: false,
        valueFormatter: (val, row) => '$'+  Two_decimals((row?.user_amount + row?.captured_refunded_amount - row?.captured_covered_amount)/100),
       },
       ...(role == "taggr_solutions" || role == "tire_tag" ? [{ 
        field: "captured_refunded_amount",
        headerName: "Collected Refund Amount",
        width: 200,
        hideable: false,
        editable: false,
        valueFormatter: (val, row) => '-$'+  Two_decimals(val/100),
       }] : []),
      {
        field: "user_destination",
        headerName: "User account",
        width: 150,
        valueFormatter: (val) => (val),
      },
      {
        field: "transfer_group",
        headerName: "Payout transfer Group",
        width: 150,
      },
      {
        field: "status",
        headerName: "Status",
        width: 150,
        renderCell: (row) => 
        { var val = row?.row;
        return  <td align="center">
                            <span className={status_classes[val?.status] || 'red'}>
                              {capitalize(val?.status ?? "")}
                            </span>
                          </td>
        },
      },
      {
        field: "user_type",
        headerName: "Type",
        width: 150,
        valueFormatter: (val) => capitalize(val ?? "")
      },
      {
        field: "refunded_citation_data",
        headerName: "Refunded citations",
        width: 150,
        valueFormatter: (val) =>  val
      },
      {
        field: "createdAt",
        headerName: "Created at",
        width: 150,
        valueFormatter: (val, row) =>  moment(val)?.tz(tz).format('YYYY-MM-DD HH:mm:ss')
  
      },
      { field: "b", headerName: "Actions", width: 200, renderCell: (row) => 
        {
          var val = row?.row;
          return <div className="d-flex align-items-center">
          <Link
              to={`/allcitations?payoutId=${val?._id}` + `&role=${role}&start_date=${startDate}&end_date=${endDate}&page=${currentPage}&limit=10`}
              className="eyeBtn DarkBlackBtn mt-2"
              >
              <img src={DarkView} className="DarkImg" />
              <img src={WhiteView} className="WhiteImg" />
            </Link>
            {val?.refunded_citation_data > 0 && <Link
              to={`/refundLogs?payoutId=${val?._id}` + `&role=${role}&start_date=${startDate}&end_date=${endDate}&page=${currentPage}&limit=10&isDev=true`}
              className="eyeBtn DarkBlackBtn mt-2"
              >
              Logs
            </Link>
            }
              </div>
        }
      },
    ];

    return (
        <>
            <section className="adminDashboard">
                <OperatorHeader />
                <div className="adminreport-wrapper" id="profile-wrapper">
                    <div className="d-flex  align-items-center">
                        <img
                            src={leftArrow}
                            className="left-arrow"
                            onClick={() => navigate(-1)}
                        />
                        <h4>Payouts</h4>
                    </div>
                    <div className="payoutscardSection">
                        <ul className="nav nav-tabs">
                            {/* <li className="nav-item"
                                onClick={() => {
                                    setRole("operator")
                                    fetchvalue("operator")
                                }}>
                                <h6>Operator</h6>
                                <a className="nav-link active" data-bs-toggle="tab" href="#home">
                                    <div className="payoutsCards">
                                        <h1><span>$</span>{data?.statistics?.operator}</h1>
                                    </div>
                                </a>
                            </li> */}
                        </ul>
                        <div className="d-flex align-items-center justify-content-evenly gap-3 border-block">
                            <button disabled={isBackwardDisable} onClick={handleBackward}><img src={payoutleftArrow} /></button>
                            <div className=" d-flex align-items-center justify-content-center gap-3">
                                <div className="date-picker-list flex">
                                    <label className="p-4">Payout Date </label>
                                    <div className="pt-3">
                                    <input
                                      style={{ width: 'min-content', height: '40px'}}
                                      value={endDate}
                                      onChange={(date) => setendDate(date)}
                                      placeholder={"Payout_date"}
                                      className="date-picker"
                                      disabled
                                    />
                                    </div>
                                </div>
                            </div>
                            {<button disabled={isForwardDisable} onClick={handleForward}><img src={payoutrightArrow} /></button>}
                        </div>
                        <div className="tab-content">
                        <div className="cirtation-wrapper tab-pane active" id="home">
                          <div className="table-responsive">
                            {data?.payouts?.length > 0 ? <DataGrid
                            rows={data?.payouts?.map((citation, index) => (citation)
                            )}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            hideFooterPagination
                            hideFooter
                            // hideFooterSelectedRowCount
                            pagination
                            // onPageChange={(params) => setPageState({ currentPage: params.page })}
                            rowCount={data?.total}
                            
                            paginationMode="server"
                            loading={props.loading}
                            getRowId={(row) => row._id}
                            />: <h2 style={{
                                maxWidth: "fit-content",
                                marginLeft: "auto",
                                marginRight: "auto",
                                padding: "10%"
                              }}
                              > No record found</h2>}
                            {data?.payouts?.length > 0 && (
                                <div className='pagination-block'>
                                <Pagination
                                    hideOnSinglePage
                                    defaultCurrent={1}
                                    onChange={page => setCurrentPage(page)}
                                    current={currentPage}
                                    pageSize={10}
                                    total={data?.total}
                                    showSizeChanger={false}
                                />
                                </div>
                            )}
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

import React, { useState } from 'react';
import close from "../../../src/images/Close.svg";

const RefundPopup = ({ onClose, reason, setReason, amount, headerTitle, receiptNumber, show, onSubmit }) => {
  return (
    <div>
      <div className={`modalContainer ${show ? "show" : ""}`} onClick={onClose}>
        <div className="modalContent" onClick={(e) => e.stopPropagation()}>
          <div className="modaHeader">
            <h5 className="text-center">{headerTitle}</h5>
            <button onClick={onClose} className='RefundCloseHeader'>
              <img src={close} alt="Close" />
            </button>
          </div>
          <div className="modalBody">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h5>Receipt Number:</h5>
              <p className="m-0">{receiptNumber}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h5>Amount:</h5>
              <p className="m-0">${amount}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h5>Reason:</h5>
              <textarea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                className="reasonTextarea"
              />
            </div>
          </div>
          <div className="modalFooter" style={{ margin: 0 }}>
            <button className="btn PrimaryBtn" onClick={onSubmit}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundPopup;

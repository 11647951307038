export const delayLoading = (state, ms) => setTimeout(() => state(false), ms ? ms : 2000);
export const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""

// debounce function
let timeoutId;
export function debounce(callback, delay) {
  clearTimeout(timeoutId);
  timeoutId = setTimeout(callback, delay);
}

export function throttleOnce(callback, delay) {
  let isCooldown = false;

  return function (...args) {
    if (!isCooldown) {
      callback.apply(this, args); 
      isCooldown = true;

      setTimeout(() => {
        isCooldown = false; 
      }, delay);
    }
  };
}

//return short name
export const returnShotLotName = (state, limit, isComaSeperated, keyname) => {
  let str, show;
  state?.map((data, index) => {
    str = (str ? `${str} ` : "") + data[keyname] + (isComaSeperated && state?.length - 1 !== index ? "," : "")
    if (state?.length - 1 === index)
      show = true
  })
  if (show)
    return <p>{str?.length > limit ? `${str.substring(0, limit)}...` : str}</p>
}

export const truncateString = (str, num) => {
  if (str?.length <= num) {
    return str;
  }
  return str?.slice(0, num) + '...';
};

export const Two_decimals = (n) => parseFloat(n?.toFixed(2))

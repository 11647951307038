import React, { Suspense, lazy, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import "./App.scss";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../utils/loading/Loading";
import Alert from "../utils/alert/Alert";
import Login from "../views/Login/container";
import Signup from "../views/Signup/container";
import ForgotPassword from "../views/ForgotPassword/container";
import NewPassword from "../views/Newpassword/container";
import Dashboard from "../views/Dashboard/container";
import YourLots from "../views/YourLots/container";
import AddLots from "../views/AddLots/container";
import LotsDetails from "../views/LotsDetails/container";
import LotEditContainer from "../views/LotEdit/Container";
import LotScheduleContainer from "../views/LotSchedule/Container.js";
import Report from "../views/Report/container";
import ConnectWithStripeComponent from "../views/ConnectWithStripe/container";
import Permit from "../views/Permit/container";
import Offence from "../views/Notices/container";
import Role from "../views/Roles/container.js";
import CreatePassword from "../views/CreatePassword/container";
import Profile from "../views/Profile/container";
import ChangePassword from "../views/ChangePassword/Container";
import Operators from "../views/Admin/admin_dashboard/container";
import AdminReport from "../views/Admin/Report/container";
import Taggr from "../views/Admin/Taggr/container";
import OperatorList from "../views/Admin/Operators-List/container";
import ContactSupport from "../views/ContactSupport/container";
import CitationsOperators from "../views/Admin/operators/container";
import CitationsTaggr from "../views/Admin/1099/container";
import AdminCitations from "../views/Admin/AdminCitations/container";
// import AdminCitationsContainer_test from "../views/Admin/Test_AdminCitations/container";
import CitationsInformation from "../views/Admin/CitationsInformation/container";
import OperatorCitations from "../views/OperatorCitation/container";
import OperatorMailInCitation from "../views/OperatorMailInCitation/container";
import Operator_citation_information from "../views/CitationsInformation/container";
import Edit_CitationsInformation from "../views/Admin/Edit_CitationsInformation/container";
import { Navigate } from "react-router-dom";
import HelloAdmin from "../views/HelloAdmin/component";
import Payouts from "../views/Admin/Payouts/container";
import CurrentPayout from "../views/Admin/Payouts-Current/container.js";
import OldPayouts from "../views/Admin/Old-Payouts/container";
import PayoutDetail from "../views/Admin/PayoutsDetail/component";
import TireTags from "../views/Admin/TireTags/container";
import TaggrProofComponent from "../views/Chatbot/Taggr_proof.js";
import SubmitProofContainer from "../views/Chatbot/Container";
import SubmitProof from "../views/Chatbot/submit_proof_2.js";
import AdminDisputedCitationsContainer from "../views/Admin/DisputedCitations/container";
import AdminDisputedCitationsInformationContainer from "../views/Admin/DisputeCitationsInformation/container.js";
import OperatorDisputedCitationsContainer from "../views/DisputedCitations/container";
import OperatorDisputedCitationsInformationContainer from "../views/DisputeCitationsInformation/container.js";
import AdminMailInCitations from "../views/Admin/AdminMailInCitations/container.js";
import ManagerContainer from "../views/Managers/container";
import AddManagerContainer from "../views/Managers/AddManagerContainer";
import DetailManagerContainer from "../views/Managers/DetailManagerContainer";
import TaggrActivitiesContainer from "../views/Admin/taggr_activities/container.js";
import PayoutCitationsContainer from "../views/Admin/PayoutCitations/container.js";
import OperatorCurrentPayoutPage from "../views/Payouts-Current_Operator/container.js";
import OperatorPayoutPage from "../views/Admin/Payouts-Operator/container.js";
import RefundRequestContainer from '../views/Admin/refund_request/container'
import EquipmentFeeContainer from "../views/Admin/EquipmentFee/Container.js";
const Receipt = lazy(() => import("../views/Admin/Receipts/container"))
const Inventory = lazy(() => import("../views/Inventory/container"))
const AddGeoFenceContainer = lazy(() => 
  import("../views/LotEdit/Container")
  .then(module => ({default: module.AddGeoFenceContainer}))
)
const EquipmentReceipt = lazy(() => 
  import("../views/Admin/Receipts/container")
  .then(module => ({default: module.EeuipmentContainer}))
)

function Wrapper(props) {
  const loadingState = useSelector((state) => state?.loading);
  const alert = useSelector((state) => state?.alert);
  const dispatch = useDispatch();
  const [permissions, setPermissions] = useState({});

  return (
    <>
      {alert?.status !== "hide" && <Alert />}
      {loadingState?.isLoading && <Loading />}
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/forgot-password"
            element={<ForgotPassword />}
          />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/hello-admin" element={<HelloAdmin />} />
          <Route path="/lots" element={<YourLots />} />
          <Route path="/managers" element={<ManagerContainer />} />
          <Route path="/manager/action" element={<AddManagerContainer />} />
          <Route path="/manager/action/detail/:managerId" element={<DetailManagerContainer />} />
          <Route path="/addlots" element={<AddLots />} />
          <Route path="/report" element={<Report />} />
          <Route path="/lot/details/:id" element={<LotsDetails />} />
          <Route path="/lot/edit/:id" element={<LotEditContainer />} />
          <Route path="/lot/schedule/:id" element={<LotScheduleContainer />} />
          <Route path="/connectWithStripe" element={<ConnectWithStripeComponent />} />
          <Route path="/permit" element={<Permit />} />
          <Route path="/offence" element={<Offence />} />
          <Route path="/role" element={<Role />} />
          <Route path="/support" element={<ContactSupport />} />
          <Route path="/create_password" element={<CreatePassword />} />
          <Route path="/change_password" element={<ChangePassword />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/admin" element={<Operators />} />
          <Route path="/adminreport" element={<AdminReport />} />
          <Route path="/taggr/:id" element={<Taggr />} />
          <Route path="/operator/:id" element={<OperatorList />} />
          <Route path="/operators" element={<CitationsOperators />} />
          <Route path="/1099" element={<CitationsTaggr />} />
          <Route path="/admincitations" element={<AdminCitations />} />
          {/* <Route path="/admin-mail-in-citations" element={<AdminMailInCitations />} /> */}
          <Route path="/tire-tags" element={<TireTags />} />
          {/* <Route path="/admincitations_test" element={<AdminCitationsContainer_test />} /> */}
          <Route path="/operatorcitations" element={<OperatorCitations />} />
          {/* <Route path="/operator-mail-in-citations" element={<OperatorMailInCitation />} /> */}
          <Route path="/citations/detail/:id" element={<Operator_citation_information />} />
          <Route path="/allcitations" element={<PayoutCitationsContainer />} />
          <Route path="/refundLogs" element={<PayoutCitationsContainer />} />
          <Route path="/citationsInformation/:id" element={<CitationsInformation />} />
          <Route path="/Edit_citationsInformation/:id" element={<Edit_CitationsInformation />} />
          <Route path="/payouts" element={<Payouts />} />
          <Route path="/current-payout" element={<CurrentPayout/>} />
          <Route path="/operator-current-payout" element={<OperatorCurrentPayoutPage/>} />
          <Route path="/operator-payout" element={<OperatorPayoutPage/>} />
          <Route path="/old-payouts" element={<OldPayouts />} />
          <Route path="/payoutdetails" element={<PayoutDetail />} />
          <Route path="/chatbot" element={<TaggrProofComponent />} />
          <Route path="/chatbot/submit_proof" element={<SubmitProofContainer />} />
          <Route path="/dispute_chatbot/submit_proof" element={<SubmitProof />} />
          <Route path="/adminDisputedCitations" element={<AdminDisputedCitationsContainer />} />
          <Route path="/adminDisputedCitations/:id" element={<AdminDisputedCitationsInformationContainer />} />
          <Route path="/taggr_activities" element={<TaggrActivitiesContainer />} />
          <Route path="/requests" element={<RefundRequestContainer />} />
          <Route path="/equipment-fee" element={<EquipmentFeeContainer />} />
          <Route path="/operatorDisputedCitations" element={<OperatorDisputedCitationsContainer />} />
          <Route path="/operatorDisputedCitations/:id" element={<OperatorDisputedCitationsInformationContainer />} />
          <Route
            path="/password-reset/:id/:token"
            element={<NewPassword />}
          />
           <Route
              path="/admin/receipt/:citationId"
              element={
                <Suspense fallback={<p>Loading...</p>}>
                  <Receipt />
                </Suspense>
              }
            />
           <Route
              path="/admin/equipment-receipt/:citationId"
              element={
                <Suspense fallback={<p>Loading...</p>}>
                  <EquipmentReceipt />
                </Suspense>
              }
            />
          <Route 
            path="/lot/geofence/:id"
            element={
              <Suspense fallback={<p>Loading...</p>}>
                <AddGeoFenceContainer />
              </Suspense>
            }
          />
          <Route 
            path="/inventory"
            element={
              <Suspense fallback={<p>Loading...</p>}>
                <Inventory />
              </Suspense>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default Wrapper;

import React from "react";

const styles = {
  badgeContainer: {
    height: "25px",
    width: "25px",
    margin: "2px",
  },
};

function VerificationBadge({ status }) {
  return (
    <div style={styles.badgeContainer}>
      {status === "verified" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 90 112.5"
          enable-background="new 0 0 90 90"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="green"
            d="M58.221,30.979c0.672,0.018,1.303,0.258,1.787,0.719l2.344,2.293  c1.094,1.068,0.953,2.932-0.313,4.172L41.455,58.261c-1,0.977-2.891,1.047-3.969,0l-9.521-9.301c-1.266-1.238-1.4-3.1-0.307-4.166  l2.338-2.291c1.1-1.068,3.006-0.938,4.271,0.295l5.203,5.084l16.26-15.879C56.449,31.308,57.361,30.96,58.221,30.979z M53.34,8.636  c-1.344-0.047-2.76,0.305-4.197,1.059c-3.146,1.652-5.521,1.313-8.287,0c-1.453-0.691-2.885-1.035-4.219-0.996  c-2.229,0.07-4.213,1.23-5.697,3.648c-1.881,3.066-4.141,3.836-7.182,4.15c-4.371,0.443-7.152,2.844-7.271,7.264  c-0.094,3.594-1.662,5.387-4.141,7.18c-3.563,2.572-4.76,6.035-2.656,9.926c1.713,3.156,1.256,5.488,0,8.281  c-1.797,4.016-1.119,7.609,2.656,9.926c3.057,1.883,3.828,4.131,4.141,7.179c0.447,4.371,2.85,7.146,7.271,7.27  c3.588,0.102,5.391,1.658,7.182,4.143c2.656,3.697,5.963,4.707,9.916,2.662c3.184-1.643,5.5-1.293,8.287,0  c4.031,1.879,7.271,1.035,9.922-2.662c1.791-2.484,3.594-4.041,7.182-4.143c4.422-0.123,6.824-2.898,7.271-7.27  c0.313-3.048,1.084-5.296,4.141-7.179c3.771-2.316,4.459-5.91,2.656-9.926c-1.256-2.793-1.709-5.125,0-8.281  c2.109-3.891,0.912-7.354-2.656-9.926c-2.484-1.793-4.041-3.586-4.141-7.18c-0.115-4.42-2.9-6.82-7.271-7.264  c-3.047-0.314-5.297-1.084-7.182-4.15C57.586,9.929,55.57,8.716,53.34,8.636z"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 90 112.5"
          enable-background="new 0 0 90 90"
        >
          <rect x="0" y="0" width="90" height="112.5" fill="none" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#ff4000"
            d="M53.34,8.636c-1.344-0.047-2.76,0.305-4.197,1.059c-3.146,1.652-5.521,1.313-8.287,0
    c-1.453-0.691-2.885-1.035-4.219-0.996c-2.229,0.07-4.213,1.23-5.697,3.648c-1.881,3.066-4.141,3.836-7.182,4.15
    c-4.371,0.443-7.152,2.844-7.271,7.264c-0.094,3.594-1.662,5.387-4.141,7.18c-3.563,2.572-4.76,6.035-2.656,9.926
    c1.713,3.156,1.256,5.488,0,8.281c-1.797,4.016-1.119,7.609,2.656,9.926c3.057,1.883,3.828,4.131,4.141,7.179
    c0.447,4.371,2.85,7.146,7.271,7.27c3.588,0.102,5.391,1.658,7.182,4.143c2.656,3.697,5.963,4.707,9.916,2.662
    c3.184-1.643,5.5-1.293,8.287,0c4.031,1.879,7.271,1.035,9.922-2.662c1.791-2.484,3.594-4.041,7.182-4.143
    c4.422-0.123,6.824-2.898,7.271-7.27c0.313-3.048,1.084-5.296,4.141-7.179c3.771-2.316,4.459-5.91,2.656-9.926
    c-1.256-2.793-1.709-5.125,0-8.281c2.109-3.891,0.912-7.354-2.656-9.926c-2.484-1.793-4.041-3.586-4.141-7.18
    c-0.115-4.42-2.9-6.82-7.271-7.264c-3.047-0.314-5.297-1.084-7.182-4.15C57.586,9.929,55.57,8.716,53.34,8.636z"
          />

          <rect
            x="27"
            y="40"
            width="35"
            height="7"
            fill="white"
            transform="rotate(45 45 45)"
          />
          <rect
            x="28"
            y="40"
            width="35"
            height="7"
            fill="white"
            transform="rotate(-45 45 45)"
          />
        </svg>
      )}
    </div>
  );
}

export default VerificationBadge;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import OperatorHeader from '../../components/common/Header/container';
import leftArrow from "../../images/left-arrow.svg";
import { Grid, Button, TextField } from '@mui/material';
import { setAlert, setLoading } from "../../store/actioncreator";
import { useNavigate, useParams } from "react-router-dom";
import * as moment from "moment-timezone";
import useIsMobile from "../../hooks/useIsMobile";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; // Import the date adapter

export default function LotScheduleComponent(props) {
  const { id } = useParams();
  const isMobile = useIsMobile();
  const timezone = moment.tz.guess();
  const navigate = useNavigate();
  const [schedules, setSchedules] = useState([{ startDatetime: null, endDatetime: null }]);
  const dispatch = useDispatch();

  const convertToLocal = (datetime) => {
    return datetime ? moment(datetime).tz(timezone).toDate() : null;
  };

  useEffect(() => {
    const fetchSchedules = async () => {
      dispatch(setLoading(true));
      try {
        const { value } = await props?.getSchedule(id);
        if (value?.success) {
          setSchedules(
            value?.schedules?.length > 0 ?
            value?.schedules?.map(schedule => 
              ({ startDatetime: convertToLocal(schedule?.startDate), endDatetime: convertToLocal(schedule?.endDate) })) 
            : [{ startDatetime: null, endDatetime: null }]
          );
        } else {
          dispatch(setAlert("error", value?.message));
        }
      } catch (error) {
        dispatch(setAlert("error", error?.message));
      }
      dispatch(setLoading(false));
    };
    fetchSchedules();
  }, [id, dispatch, props]);

  const handleAddRow = () => {
    setSchedules([...schedules, { startDatetime: null, endDatetime: null }]);
  };

  const handleChange = (index, field, value) => {
    const updatedSchedules = schedules.map((schedule, i) =>
      i === index ? { ...schedule, [field]: value } : schedule
    );
    setSchedules(updatedSchedules);
  };

  const handleSubmit = async () => {
    const currentDateTime = new Date(); // Get the current date and time

    const invalidSchedules = schedules?.some((schedule) => {
      const startDatetime = new Date(schedule?.startDatetime);
      const endDatetime = new Date(schedule?.endDatetime);
  
      // Check if only one of the dates is filled
      if ((schedule?.startDatetime && !schedule?.endDatetime) || (!schedule?.startDatetime && schedule?.endDatetime)) {
        return true;
      }
  
      // Check if endDatetime is not greater than startDatetime or the current date
      if (schedule?.startDatetime && schedule?.endDatetime) {
        if (endDatetime <= startDatetime) {
          return true;
        }
      }
  
      return false;
    });

    if (invalidSchedules) {
      dispatch(setAlert("info", "Ensure both start and end dates are filled, and the end date is later than the start date and current date."));
    return;
    }
    dispatch(setLoading(true));
    try {
      const { value } = await props?.createSchedule(id, schedules, timezone);
      if (value?.success) {
        navigate("/lots");
        dispatch(setAlert("success", value?.message));
      } else {
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
    dispatch(setLoading(false));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}> {/* Wrap with LocalizationProvider */}
      <section className="mainWrapper">
        <OperatorHeader />
        <div className="main-wrapper paddzero" id="profile-wrapper">
          <div className="HeadingSection addlote-title">
            <img src={leftArrow} className="left-arrow" onClick={() => navigate(-1)} alt="Go Back" />
            <h4>Lot Scheduling</h4>
          </div>
          <Grid container spacing={1} sx={{
              width: isMobile ? 'calc(100% - 32px)' : '90%' 
            }}
            mx="auto"  my={2}>
            {schedules.map((schedule, index) => (
              <Grid container item spacing={2} style={{marginBottom: "10px"}} alignItems="center" key={index}>
                <Grid item xs={isMobile ? 16 : 12} sm={4}>
                  <DateTimePicker
                    label="Start Date & Time"
                    inputFormat="DD/MM/YYYY HH:mm"
                    value ={schedule.startDatetime}
                    onChange={(newValue) => handleChange(index, 'startDatetime', newValue)}
                    renderInput={(params) => <TextField {...params}  sx={{
                      '& .MuiFormControl-root': { width: '100%' }, // Ensure full width
                      '& .MuiTextField-root': { width: '100%' },   // Ensure full width
                    }} />}
                    minDateTime={moment().tz(timezone).toDate()} // Disable past dates
                  />
                </Grid>
                <Grid item xs={isMobile ? 16 : 12} sm={4}>
                  <DateTimePicker
                    label="End Date & Time"
                    value={schedule.endDatetime}
                    onChange={(newValue) => handleChange(index, 'endDatetime', newValue)}
                    renderInput={(params) => <TextField {...params}  sx={{
                      '& .MuiFormControl-root': { width: '100%' }, // Ensure full width
                      '& .MuiTextField-root': { width: '100%' },   // Ensure full width
                    }} />}
                    minDateTime={moment().tz(timezone).toDate()} // Disable past dates
                    inputFormat="DD/MM/YYYY HH:mm"
                  />
                </Grid>
                <Grid item xs={isMobile ? 12 : 12} sm={4}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                      setSchedules(schedules.filter((_, i) => i !== index))
                    }
                    disabled={schedules.length === 1}
                    fullWidth={isMobile}
                  >
                    Remove
                  </Button>
                </Grid>
                <Grid item xs={isMobile ? 12 : 8} sm={isMobile ? 6: 3}>
                </Grid>
              </Grid>
            ))}
             <Grid item xs={12} sx={{ mt: 1 }}>
              <Button variant="outlined" color="primary" onClick={handleAddRow} fullWidth={isMobile}>
                Add More
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth={isMobile}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </div>
      </section>
    </LocalizationProvider>
  );
}